import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mqtt from 'mqtt'
import './quasar'
import VueKonva from 'vue-konva'
import i18n from '@/locale'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import echarts from 'echarts'
import vuePickers from 'vue-pickers'
//import SvgIcon from 'vue-svgicon'
//import './icons/interaction'
import vueDrag from 'vue-dragging'
import VueLazyload from 'vue-lazyload'
//import animate from 'animate.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { Quasar, QDialog, ClosePopup, Loading, QRadio } from 'quasar'
import apiTools from '@/api'
import commons from "@/filter/guid.js"
//import jwtDecode from 'jwt-decode'
import './assets/mobile/common.css' /*���빫����ʽ*/
import { Button } from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'//ʱ��ת��
// import mqttconnect from './plugin/mqttconnect.js' // MQTT���ӷ���
import draggable from 'vuedraggable'    //�϶����
import {jsFn} from '@/utils/jsFn.js'
import './assets/icons';

// Vue.use(mqttconnect) 
Vue.use(draggable) 
require('@/assets/css/common.css')
require('@/assets/css/style.less')
library.add(fas, far, fab)

Vue.prototype.$moment = moment;//��ֵʹ��

Vue.config.devtools = true
Vue.config.productionTip = false

Object.defineProperty(Vue.prototype, '$mqtt', { value: mqtt });
Vue.prototype.common = commons
Vue.prototype.$echarts = echarts
Vue.prototype.$api = apiTools
Vue.prototype.$jsFn = jsFn
//Vue.prototype.$jwtDecode = jwtDecode
Vue.use(Button);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.use(iView, {
    i18n: (key, value) => i18n.t(key, value)
})
Vue.prototype.$Message.config({
    top: 70,
    duration: 2
});

Vue.use(Quasar, {
    plugins: {
        Loading,
        QRadio
    },
    config: {
        loading: { /* Loading defaults */ }
    },
    components: {
        QDialog,
        Loading,
        QRadio,
    },
    directives: {
        ClosePopup
    }
})

//Vue.use(SvgIcon, { tagName: 'svgicon' })
Vue.use(vuePickers)
Vue.use(vueDrag)
Vue.use(VueKonva)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: '/loading2.gif',
})


// 登錄中間驗證，頁面需要登錄而沒有登錄的情況直接跳轉登錄
router.beforeEach((to, from, next) => {
    // console.log('to=', to.fullPath, '| from=', from.fullPath)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!!sessionStorage.getItem('idToken')) {
            next()
        } else {
            next({ name: 'login' })
        }
    } else {
        if(to.fullPath === '/' && !!sessionStorage.getItem('idToken')){
            next({ name: 'board' })
        } else {
            next()
        }
    }
})

store.dispatch('funcs/keepState')
store.dispatch('preferences/keepState')
store.dispatch('classInfo/keepState')
store.dispatch('mqtt/keepState')

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
