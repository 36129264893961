import { jsFn } from '@/utils/jsFn'
const de = require('@/utils/lib1.js')
import enc from '@/utils/enc.js'

const KEEPSTORAGE = 'KEEPSTORAGE'
const SETUPCLASSINFO = 'SETUPCLASSINFO'

export default {
    namespaced: true,
    state: {
        // 重啟課堂 要重拿activityRecordNumber
        classInfo: {
            activityRecordNumber: '',
            students: [],
            materialUrl: '', // 上課教材
            schoolId: '',  // 學校ID
            schoolName: '', // 學校名稱
            className: '', // 課堂名稱
            workingFolder: 'temp/HiTeachCC/', // 工作路徑
            blob: {
                sas: '',
                url:'',
                sas_write: '',
                sas_read: ''
            },
            scope: 'private',
            isIES5: false
        }
    },
    getters: {
        getInfo: state=> state.classInfo, // 取得選擇題預設類型
    },
    mutations: {
        [KEEPSTORAGE](state, data) {
            let keys = Object.keys(data)
            keys.forEach(key => {
                state[key] = data[key]
            })
        },
        [SETUPCLASSINFO](state, data) {
            state.classInfo = data
        }
    },
    actions: {
        /**
         * NOTE: 初始課堂資訊
         * @param {Object} data
         */
        init(context, data) {
            let temp = context.state.classInfo

            temp.activityRecordNumber = 'HiCC-' + jsFn.getUUID()

            if(data.blob){
                console.log('data.blob')
                temp.blob.url = data.blob.url
                temp.blob.sas_write = data.blob.sas_write
                temp.blob.sas_read = data.blob.sas_read
            }            

            context.commit(SETUPCLASSINFO, temp)
            context.dispatch('saveState') // 存到sessionStorage
        },
        
        /**
         * NOTE: 設定IES5課堂資訊
         */
        setupByIES5(context, data) {
            let temp = context.state.classInfo

            temp.isIES5 = true
            temp.activityRecordNumber = data.activityRecordNumber ? data.activityRecordNumber : temp.activityRecordNumber // 如果有值就修改 沒有不動作
            temp.materialUrl = data.materialUrl
            temp.schoolId = data.schoolId
            temp.schoolName = data.schoolName
            temp.className = data.className
            temp.scope = data.scope
            // 暫時開啟功能測試
            temp.workingFolder = data.workingFolder
            if(data.scope == 'school') {
                temp.blob.url = data.blob.url
                temp.blob.sas_write = data.blob.write
                temp.blob.sas_read = data.blob.read
            }
            temp.students = [] // 先清空

            if(Array.isArray(data.students)){
                let time = Math.floor(Date.now())
                data.students.forEach((item, index)=>{
                    let number = item.irs ? item.irs : item.no ? item.no : (index + 1) // 以irs優先，沒有以順序
                    let student = {
                        sort: number,
                        id: '',
                        accountid: item.id,
                        studentName: item.name,
                        sendTime: time,
                        headImg: item.picture ? item.picture : '',
                        option: '',
                        done: true,
                        score: 0,
                        rank: '',
                        class: 'studyboxone',
                        showcardclass: 'brand',
                        answerclass: 'studyboxone',
                        answerbrand: 'brand',
                        statement: '', //发言,
                        status: 'offline',
                        type: item.type
                    }
                    temp.students.push(student)
                })
                // 調整排序
                temp.students.sort(function(a, b){
                    return a.sort - b.sort
                })
            }
            context.commit(SETUPCLASSINFO, temp)
            context.dispatch('saveState') // 存到sessionStorage
        },
        /** 
         * NOTE: 維持 vuex state 的資訊
        */
        keepState(context) {
            if(!jsFn.isEmpty(sessionStorage.getItem('classInfoData'))) {
                // let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('classInfoData'),'utf-8'))
                let data = JSON.parse(enc.decrypt(sessionStorage.getItem('classInfoData'), de.text))
                context.commit(KEEPSTORAGE, data)
            }
        },
        /** 
        * NOTE: 儲存現在 state 的資訊
        */
        saveState(context) {
            let saveData = {
                classInfo: context.state.classInfo
            }
            sessionStorage.setItem('classInfoData', enc.encrypt(JSON.stringify(saveData), de.text))                

            // sessionStorage.setItem('classInfoData', encodeURIComponent(JSON.stringify(saveData), 'utf-8'))
        }
    }
}
