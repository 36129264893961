import axios from 'axios';
import Vue from 'vue'
import router from '@/router.js';
import { jsFn } from '@/utils/jsFn'
import profile from '@/utils/profile'

let refreshing = false
var serverLocation = jsFn.isCN() ? 'cn' : 'global'
axios.defaults.timeout = 1000000; //设置超时时长
axios.defaults.baseURL = '';

//http request 拦截器
axios.interceptors.request.use(
    async(config) => {
        // const token = getCookie('名称');
        config.data = JSON.stringify(config.data);
        if (config.url.indexOf('coreapiscn') != -1) {//台湾接口
            if (sessionStorage.getItem('TWtoken')) {
                config.headers = {
                    'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem('TWtoken'),
                }
            } else if (sessionStorage.getItem('mobiletoken')) {
                config.headers = {
                    'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem('mobiletoken')
                }
            }
        } else if (config.url.indexOf('api2') != -1) {//api2
            let flag = checkTokenExpired()
            if (flag) {
                if (!refreshing) {
                    if (sessionStorage.getItem('access_token')) {
                        await refreshToken()
                    }
                }
            }
            //IM header
            if (sessionStorage.getItem('access_token')) {
                config.headers['Authorization'] = sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token')
                config.headers['Content-Type'] = 'application/json'
            } else {
                config.headers['Content-Type'] = 'application/json'
            }
        } else if (config.url.indexOf('messages') != -1) {
            console.log('进入发送消息头部')
            var UserID = JSON.parse(sessionStorage.getItem('user'))
            config.headers = {
                "Content-Type": "application/json",
                "X-Auth-UserID": UserID.sub,
                "X-Auth-Channel": sessionStorage.getItem('classNum'),
                
            }
        } else if (config.url.indexOf('teammodel') != -1) {//ies5API
            let flag = checkTokenExpired()
            if (flag) {
                if (!refreshing) {
                    if (sessionStorage.getItem('access_token')) {
                        await refreshToken()
                    }
                }

            }
            config.headers['accept'] = 'application/json'
            config.headers['Authorization'] = sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token')
            config.headers['Content-Type'] = 'application/json'
            config.headers['X-Auth-IdToken'] = sessionStorage.getItem('idToken')
        } else {
            if (sessionStorage.getItem('TMDtoken')) {
                if (config.url.indexOf('UploadDocument') != -1) {//上传接口
                    config.headers = {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('TMDtoken'),
                        'lang': localStorage.getItem('local')
                    }
                } else {
                    config.headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('TMDtoken'),
                        'lang': localStorage.getItem('local')
                    }
                }
            } else {
                config.headers = {
                    'Content-Type': 'application/json',
                    'Authorization': ""
                }
            }
        }
        //新增blob数据头部
        //if (sessionStorage.getItem('idToken')) {
        //    if (config.url.indexOf('parse-doc') != -1) {
        //        //console.log(sessionStorage.getItem('idToken'))
        //        //console.log(JSON.parse(sessionStorage.getItem('idToken')))
        //        config.headers['X-Auth-IdToken'] = sessionStorage.getItem('token_type') + sessionStorage.getItem('idToken')
        //        config.headers['Content-Type'] = 'application/json'
        //    }
        //}
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);


/** 檢查Token是否快過期 */
function checkTokenExpired() {
    if (!sessionStorage.getItem('expires_in') || sessionStorage.getItem('expires_in') === undefined) return false
    var nowTime = new Date()
    let cT = Date.parse(nowTime)
    let eT = Date.parse(sessionStorage.getItem('expires_in'))
    let btw = eT - cT
    if (btw < 0) {
        return true
    } else {
        return false
    }
}

/** 刷新token */
async function refreshToken() {
    refreshing = true
    await axios.post(profile.apiUrl[serverLocation] + '/oauth2/token', {
        grant_type: 'refresh_token',
        client_id: profile.clientID[serverLocation],
        access_token: sessionStorage.getItem('access_token')
    }).then(
        res => {
            sessionStorage.setItem('access_token', res.data.access_token)
            sessionStorage.setItem('expires_in', res.data.expires_in)
            refreshing = false
        }, (err) => {
            console.log(err)
            refreshing = false
        }
    )
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params) {
    let data = {};
    data.method = url;
    data.params = params;
    data.lang = localStorage.getItem('local');
    return new Promise((resolve, reject) => {
        axios.get(url, data)
            .then(response => {
                resolve(response.data);
                //  this.$Message.success('数据访问成功！');
            })
            .catch(err => {
                reject(err);
                this.$Message.error('数据访问错误！');
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, params) {
    let data = {};
    data.method = url;
    data.params = params;
    data.lang = localStorage.getItem('local');
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(response => {
                resolve(response.data);
                // this.$Message.success('数据访问成功！');
            }, err => {
                reject(err);
                // this.$Message.error('数据访问错误！');
            })
    })
}
