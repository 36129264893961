export default {
    text1: '收課總數',
    text2: '教師數',
    text3: '特級典藏數',
    text4: '高級典藏數',
    text5: '一級典藏數',
    text6: '本日',
    text7: '近七日',
    text8: '近30日',
    text9: '本學期',
    text10: '年級',
    text11: '科目',
    text12: '一年級',
    text13: '二年級',
    text14: '三年級',
    text15: '四年級',
    text16: '五年級',
    text17: '語文',
    text18: '數學',
    text19: '英文',
    text20: '科學',
    text21: '藝術',
    text22: '其他',
    text23: '科技互動指數關系圖(年級)',    
    text24: '科技互動指數關系圖(科目)',   
    text25: '音樂',   
    text26: '軟件測試',   
    text27: '語文',   
    text28: '英語',   
    text29: '美術',   
    text30: '科學',   
    text31: '班會',   
    text32: '數學',   
    text33: '開學課程',   
    text34: '其他',
    text35: '本日互動/教法',
    text36: '30日互動/教法',
    text37: '本學期互動/教法',
    text38: '所有互動/教法',
    text39: '計分板',
    text40: '挑人',
    text41: '計時器',
    text42: '搶權',
    text43: '統計圖',
    text44: '即問即答',
    text45: '作品比較',
    text46: '推送',
    text47: '翻牌',
    text48: '二次作答',
    text49: '常用科技分布圖',
    text50: '總量',
    text52: '語文',
    text53: '英語',
    text54: '數學',
    text55: '科學',
    text56: '綜合',
    text57: '教法應用指數關系圖(年級)',
    text58: '教法應用指數關系圖(科目)',
    text59:  '互動 = 技術互動指數',
    text60:  '教法 = 教學應用指數',
    text61:  '裡，',
    text62:  '區間的一共有',
    text63:  '人',
    text64:  '年級',
}