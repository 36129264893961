import { jsFn } from '@/utils/jsFn'
import api from '@/api'
import i18n from '@/locale'
const KEEPSTORAGE = 'KEEPSTORAGE'
const SETUPPREF = 'SETUPPREF'
export default {
    namespaced: true,
    state: {
        pref: { // 以下皆為預設設定
            MCQType: 'En', // 選擇題選項
            IRSBlockSize: '20', // IRS Block 大小
            pens: [ // 各種筆的單獨設定
                {
                    type: 'pencilAlt',
                    color: 'red',
                    size: '1',
                },
                {
                    type: 'highlighter',
                    color: 'orange',
                    size: '2',
                },
                {
                    type: 'magic',
                    color: 'yellow',
                    size: '3',
                },
            ],
            memberListIDs:[],
            gptActionList: [{ value: i18n.t('gptBox["總結大意"]') }, { value: i18n.t('gptBox["整理同義字"]') }, {value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""}],
        }
    },
    getters: {
       getMCQType: state=> state.pref.MCQType, // 取得選擇題預設類型
       getIRSBlockSize: state => state.pref.IRSBlockSize, // 取得IRS Block 大小
       getPenState: state => (type) => {
        return state.pref.pens.find(item => item.type === type)
       },
       getPREFMemberList:state=>state.pref.memberListIDs, // 取得自訂名單,
       getGptActionList:state => state.pref.gptActionList
    },
    mutations: {
        [KEEPSTORAGE](state, data) {
            let keys = Object.keys(data)
            keys.forEach(key => {
                state[key] = data[key]
            })
        },
        [SETUPPREF](state, data) {
            state.pref = data
        }
    },
    actions: {
        /**
         * 修改偏好設定
         * @param {Object} data 
         */
        updProfilePREF(context, data) {
            return new Promise(async(resolve) => {
                let tmp = {
                    pref: data
                }
                await api.coreApi.setSettings(sessionStorage.getItem('idToken') , tmp).then( () => {
                    context.commit(SETUPPREF, data)
                    context.dispatch('saveState') // 存到sessionStorage
                    resolve(true)
                })
            })
        },
        /**
         * 設定使用者偏好設定
         * @param {Object} data 
         */
        setProfilePREF(context, data) {
            return new Promise((resolve) => {
                context.commit(SETUPPREF, data.pref)
                context.dispatch('saveState') // 存到sessionStorage
                resolve(true)
            })          
        },
        /** 
         * NOTE: 維持 vuex state 的資訊
        */
        keepState(context) {
            if(!jsFn.isEmpty(sessionStorage.getItem('prefData'))) {
                let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('prefData'),'utf-8'))
                context.commit(KEEPSTORAGE, data)
            }
        },
        /** 
        * NOTE: 儲存現在 state 的資訊
        */
        saveState(context) {
            let saveData = {
                pref: context.state.pref
            }
            sessionStorage.setItem('prefData', encodeURIComponent(JSON.stringify(saveData), 'utf-8'))
        }
    }
}
