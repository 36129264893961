import schoolMgmt from './schoolMgmt'
import classMgmt from './classMgmt'
import talMgmt from './talMgmt'
import menu from './menu'
import unit from './unit'
import common from './common'
export default {
  schoolMgmt,
  classMgmt,
  talMgmt,
  unit,
  menu,
  common,
  toolbox: {
    菜單: 'Menu',
    選擇: 'Select',
    回上一步: 'Undo',
    畫筆: 'Brush',
    圖形: 'Shape',
    插入圖片: 'Resources',
    文字: 'Text',
    橡皮擦: 'Eraser',
    上一頁: 'Previous',
    新建一頁: 'New page',
    下一頁: 'Next',
    缩放工具: 'Zoom/Pan',
    回到原始比例: 'Original Scale',
    IRS工具: 'Interactive Tools',
    推送圖片:'Send Screenshot',
    IRS即問即答: 'Multiple Choice Quiz',
    IRS填空: 'Text/Cloze Quiz',
    IRS作品收集: 'Work Collection',
    IRS搶權: 'Buzz-in',
    挑人: 'Pick-out',
    倒數計時: 'Timer',
    重設為一般挑人模式:'Reset to normal Pick-out',
    目前沒有動作可復原: 'No more undo'
  },
  board: {
    前往:'Install',
    Gpt安裝提示標題:'Installation Note',
    GPT安裝提示字:"<p>You're allowed to use GPT tools. It's best to use Google Chrome or Microsoft Edge on your computer. Download and install the extension kit to enable GPT-related functions. After installation, please log in again to activate this feature!</p>",
    清空畫布提示字:"You can't recover after clearing, are you sure you want to clear the whole page?",
    清空作品收集提示字:"You can't recover after clearing. Are you sure to clear the works that have been submitted so far?",
    網路斷線:'Detected that the network has been disconnected, please check the network!',
    SignalR協議未正常連線:'Part of the communication seems to be disconnected, it is recommended to refresh！',
    閒置視窗文字:'Idle for more than 60 minutes, please click to continue to use, no action will automatically log out after 1 minute',
    仍要使用:'Still use',
    文字工具:'Text Tool',
    輸入文字:'Enter text...',
    便利貼顏色:'Sticky note color',
    確定:'OK',
    取消:'Cancel',
    選擇新增活動時之頁面內容:'Please select the page content of the new activity',
    複製前一頁面:'Clone previous page',
    空白頁面:'Blank page',
    螢幕大小基本需求說明:"Unsupported device size. To maintain the interface operating experience, it is recommended to use HiTeach CC on a computer or tablet, and the minimum screen size and resolution requirement should be at least 1024px*600px",
    歡迎使用HiTeachCC來進行互動: 'Welcome to HiTeach CC for interaction',
    請選擇開始的動作: 'Please select the starting action',
    開啟IES5所選教材:'Open IES 5 materials',
    開啟PDF檔案: 'Open PDF file',
    直接使用畫板: 'Blank Board',
    參閱使用範例PDF:'Use Example PDF',
    您的使用權限:'Permission',
    學生連線數:'Max participants',
    作品收集數:'Max collection tasks',
    aigptEXP:'AIGPT EXP',
    權限到期:'Upgraded until',
    '無法使用，已達您的權限作品收集數上限':'Sorry, you have reached the maximum number of collection tasks with your permission',
    '偵測到有請求加入，但加入教室人數已達您的權限人數上限':'A request to join your lesson has been detected, but the number of people who joined has reached your permission limit.',
    '權益說明':'Use HiTA 5 App to scan and login to get more permission',
    IRS單選題選項設置: 'Multiple choice option setting',
    全屏幕: 'Full Screen',
    重啟課堂: 'Restart Lesson',
    結束登出: 'Logout',
    匯出PDF: 'Export PDF',
    匯出課堂記錄: 'Export Lesson Records',
    插入PDF:'Insert PDF',
    自訂學生名單:'Custom Student List',
    PDFInit:{
      開啟PDF檔案: 'Open PDF file',
      從:'From',
      到:'to',
      加到目前課堂文件:'import as slides',
      第:'',
      頁之後:'',
      使用限制提示:'Hint',
      目前課堂文件頁數:'Current total pages',
      加入頁數:'Selected pages',
      最多:'Max:',
      頁:'pages',
      總頁數:'Total pages',
      使用限制提示警告:"The number of selected pages is exceeded, please adjust!",
      PDF載入錯誤:'PDF Loading Error'
    },
    PDFInsert:{
      從:'From',
      到:'to',
      加到目前課堂文件:'import slides after current page',
      第:'',
      頁之後:'',
      使用限制提示:'Hint',
      目前課堂文件頁數:'Current total pages',
      加入頁數:'Add pages',
      最多:'Max:',
      頁:'pages',
      總頁數:'Total pages',
      使用限制提示警告:"The page limit is exceeded, please adjust!",
      PDF載入錯誤:'PDF Loading Error'
    },
    轉檔匯出中請稍候: 'PDF Exporting, please wait!',
    匯出與上傳課堂數據到IES5:'Export and upload lesson data to IES 5',
    作品收集: 'Task',
    選擇貼回之頁面:'Select the page to paste back to',
    貼到本頁:'Paste to current page',
    貼到新增的空白頁:'Paste to a new page',
    暫無數據: 'Have not received any work yet',
    請按鈕開始接收搶權:'Click button to start buzz-in',
    繼續搶權:'Continue',
    等待搶權:'Waiting for Buzz-in',
    qrcodePop: {
      '請掃碼QRcode 用瀏覽器參與課堂互動': 'Please scan the QR code to join via browser',
      已複製到剪貼簿: 'Copied to the clipboard',
      請掃碼QRcode: 'Please scan the QR code',
      用瀏覽器參與課堂互動: 'to join via browser',
      或直接在瀏覽器上輸入下列網址: 'Or enter the following URL directly on the browser',
      '提示：許多手機掃碼工具內建的瀏覽器功能受限，建議掃碼後另開系統瀏覽器': 'Tip: The built-in browser function of many cell phone scanning tools is limited, it is recommended to open with a system browser after scanning the code.',
      'allowVisitor': 'W/O Login',
      'allowSitin': 'Sitin'
    },
    物件轉換中請稍候: 'Converting, please wait...',
    請先勾選: 'You have not selected any pictures yet',
    一次勾選貼回最多6張: 'Select up to 6 pictures at a time',
    请登陆: 'Please login',
    上传成功: 'Upload successfully',
    '上传失败！请查看PDF页数是否超过20页 是否符合标准': 'The number of PDF pages has exceeded 20 pages, only the first 20 pages will be loaded',
    正在制作画板: 'Preparing pages',
    制作画板制作成功: 'Successfully prepared',
    上传格式有误: 'The uploaded file is in the wrong format',
    您上传的文件: 'Your uploaded file',
    '格式不正确, 请上传PDF格式': 'The format is incorrect, please upload PDF format file',
    上传文件大小限制: 'Upload file size limit',
    '大小超过5M, 请上传不超过5M的文件': 'The size exceeds 5MB, please upload the file not exceeding 5MB',
    正在制作中: '"In production',
    通讯连接已建立: 'Connection established',
    '加入教室失败，请尝试重连!': 'Failed to create the classroom, please try to reconnect!',
    '通讯连接失败，请尝试重连！': 'The communication connection failed, please try to reconnect!',
    單選題已結束: 'Choice quiz has ended',
    文字題已結束: 'Text quiz has ended',
    您的浏览器无法进入全屏模式: 'Your browser cannot support full screen mode',
    '账号正在退出!': 'Account is logging out',
    本頁無Quiz活動: 'There is no Quiz activity on this page',
    '正在努力加载，请稍等...': 'Now loading, please wait...',
    '正在上传文件,请稍等...': 'Now uploading, please wait...',
    toolbox: {
      '此页已有题目，自動新增一页开启互动': 'This page already has an interactive activity, so a new page is added',
      目前已經是最後一頁: 'This is the last page',
      所選圖片過大:'The selected image is too large (should be less than 3MB), please select again!',
      畫筆: 'Brush',
      大小: 'Size',
      顏色: 'Color',
      圖形: 'Shape',
      邊框: 'Border',
      填充: 'Fill',
      比例: 'Scale',
      倒數: 'Countdown',
      計時: 'Timing',
      開始計時:'Start Timing',
      暫停計時:'Pause Timer',
      分:'m',
      秒:'s',
      復位:'Reset',
      重置:'Reset',
      記次:'Record Time',
      第:'No.',
      條記錄:' Records',
      字體大小: 'Font Size',
      IRS沒有學生提示訊息:'No students have joined the lesson yet, please open the QR code for students to scan and join',
      通用畫筆:'Brush',
      高光筆:'Highlighter',
      鐳射筆:'Laser Pen',
      箭頭:'Arrow',
      直線:'Straight Line',
      圓形:'Circle',
      橢圓:'Ellipse',
      矩形:'Rectangle',
      三角形:'Triangle',
      星形:'Star',
      刪除元素:'Delete Element',
      清空畫布:'Clear All'
    },
    optionView: {
      統計翻牌自動結束: 'Auto-end after showing charts or answer',
      匿名:'Anonymous',
      複製到剪貼簿: 'Copy to clipboard',
      餅圖: 'Pie Chart',
      長條圖: 'Bar Chart',
      翻牌: 'Show Answer',
      文字便利貼: 'Text Sticky Notes',
      文字雲:'Word Cloud',
      貼回舞台:'Paste',
      停止作答: 'Stop',
      重新作答: 'Restart',
      單選題: 'Choice Quiz',
      文字題: 'Text Quiz',
      '已全部複製到剪貼簿!': 'Copy all to clipboard!',
      '答案已重新開啟！請重新作答': 'Answering has been reopened! Please answer again',
      設定答案成功:'Set the answer successfully',
      已移除答案:'Answer removed',
      設定無答案:'Remove answer',
      設定答案: 'Set Answer',
      無答案: 'No Answer',
      未作答: 'Unanswered',
      重啟作答會清除所有已收到的數據: 'Restart answering will clear all received data',
      確定: 'OK',
      取消: 'Cancel',
      人:', '
    },
    addImgBox:{
      檔案圖片:'Add picture',
      選取本機檔案圖片:'Select picture',
      剪貼簿:'Clipboard',
      暫無數據:'No content yet',
      取得複製到剪貼簿的資料:'Get clipboard content',
      轉換物件貼回:'Paste',
      瀏覽器版本不支援:'Your browser does not support this feature yet!',
      瀏覽器不允許讀剪貼簿:'Clipboard read permission denied!',
      素材庫:'Cliparts',
      背景庫:'Backgrounds',
      發送文字:'Send Text',
      文字發送成功:'Send text successfully!',
      字串長度提示:'The text is too long, only the first 500 characters are displayed!',
      確定:'OK',
      取消:'Cancel'
    },
    '暂无成员参与挑人。': 'There are currently no members involved in the selection.',
    'Uploading': 'Uploading',
    'ssoError': 'Your account is logged in on another device. If this is not your operation, please change your password immediately.',
    objMenu:{
      刪除物件:'Delete object',
      複製:'Clone object',
      移到最上層:'Move to top',
      移到最下層:'Move to bottom',
      將物件複製為圖片到剪貼簿:'Copy object as picture to clipboard',
      將物件複製為文字到剪貼簿:'Copy text to clipboard',
      已複製到剪貼簿: 'Copy to clipboard!',
      瀏覽器版本不支援:'Your browser does not support this feature yet!',
      發送文字:'Send Text',
    },
    rightClickMenu:{
      從剪貼簿貼上:'Paste from the clipboard'
    }
  },
  login: {
    formMsg: {
      error1: 'Failed to get Blob',
      error2: 'The account or password is incorrect',
      error3: 'Authorization failed with {state}',
      error4: 'Quick login failed',
      error5: 'Please scan the QR Code with HiTA 5'
    },
    currentLang: 'English',
    loginBox: {
      title: 'HiTeach CC',
      intro: 'HiTeach Cloud classroom is an online education system for teachers and students to learn knowledge. Here is the introduction.',
      loginWord: 'TEAM Model Account',
      StudentEntrance: 'Student/participant join the classroom',
      headertitle: 'HiTeach CC',
      authLoginTitle: 'More options',
      loginId: 'Email / Phone / ID',
      password: 'Password',
      regist: 'Register',
      forgotPW: 'Forgot Password?',
      qrcode:{
        text1: 'Please use ',
        text2: 'HiTA 5 ',
        text3: 'to scan',
        text4: 'Do you want to QR Code log in?',
      }
    },
    footer: {
      create: 'CREATE TMID'
    }
  },
  formConfigP: {
    input: 'Please Enter ',
    select: 'Please Select',
    country: 'Country',
    province: 'Province',
    city: 'City',
    school: 'School'
  },
  index: {
    login: 'Sign In',
    register: 'Sign Up',
    chooseRole: 'Choose Role',
    exit: 'Sign Out',
    authSchool: 'Authorized School',
    currentRole: 'Current Role',
    loginSuc: 'Login Success'
  },
  //StudentAnalysis
  saindex: {
    index: 'Home',
    grade: 'Grade Analysis',
    droppoint: 'Droppoint Analysis',
    examination: 'Analysis of test paper',
    knowledge: 'Knowledge'
  },
  sasidebar: {
    class: 'Choose focus grade',
    classmsg: 'Please select grade',
    term: 'Choose to focus on the school year',
    term: 'Please select your academic year',
    compare: 'Choose data comparison'
  },
  copyright: 'Copyright © 2024 HABOOK Group TEAM Model',
  error: {
    required: 'Required',
    format: {
      default: 'Wrong Format'
    }
  },
  remind: {
    title: 'Remind',
    content: 'The current browser is not Google Chrome, for your better experiences, it is recommended to use Google Chrome.',
    toDownload: 'Download'
  },
  loadingMsg:{
    text1: '<b>Initialization</b>...<br/><span class="text-primary">Please wait...</span>',
    text2: '<b>Login</b>...<br/><span class="text-primary">Please wait...</span>'
  },
  report: {
    title: 'Summary Report',
    activityName: 'Class/Course',
    hostName: 'Teacher',
    meterialName: 'Teaching Material',
    dateTime: 'Date Time',
    attendCount: 'Attendance',
    attendRate: 'Attendance Rate',
    collateTaskCount: 'Tasks',
    collateCount: 'Works',
    pushCount: 'Pushed Resources',
    totalInteractPoint: 'IRS Scores',
    interactionCount: 'IRS Items',
    clientInteractionCount: 'Responses',
    clientInteractionAverge: 'Average Responses',
    quizID: 'Item No.',
    pageOrderStr: 'Page',
    score: 'Score',
    correctAnswer: 'Answer',
    correctRateStr: 'Correct Rate',
    seatID: 'Seat No.',
    name: 'Name',
    attendState: 'Attendance',
    attendState1: 'Present',
    attendState0: '--',
    taskCompleteCount: 'Works',
    interactScore: 'IRS Score',
    answerList: 'Answer',
  },
  memberlist:{
    自由加入:'Ad hoc dynamic join',
    請選擇學生名單:'Please select the name list for this lesson',
    創建自訂學生名單:'Create a preset name list',
    使用IES5課程名單:'Use IES 5 Course List',
    點選左側指定可加入的學生名單:'Click on the left to select a name list',
    點選Hi進行編輯:'If you need to edit the preset name list, please operate from the Hi menu after clicking "Next"',
    下一步:'Next',
    上一步:'Previous',
    編輯學生:'Edit Student',
    新增學生:'New Student',
    姓名:'Name',
    座號:'Seat No.',
    座號重複:'Duplicate seat number',
    座號不可為空或輸入無法辨認的值:'Seat number cannot be empty or unrecognized value',
    姓名不可為空:'Name cannot be empty',
    確定:'OK',
    取消:'Cancel',
    修改名單名稱:'Edit List Name',
    表單名稱不可為空:'The list name cannot be empty',
    確定刪除後這個名單將無法復原:'This list will not be recoverable after deletion. Are you sure?',
    刪除:'Delete\t',
    自訂學生名單:'Edit Preset List',
    創建名單:'Create a List',
    暫無數據:'No data',
    新建的學生名單:'New name list...',
    輸入名單資料:'Enter list information...',
    自動編號提示字:'Unable to recognize seat number rules, system has automatically assigns numbers',
    預覽:'Preview',
    儲存預覽表格:'Save List',
    名單範例模板:`Input list tips<br><br>The format of the list is:
    Name, Seat number <br>(Seat number can be omitted, but must be filled in every row if provided)<br><br>
    Restrictions<br>
    Name: Within 50 characters<br>
    Seat number: Non-repeating digits, should be less than 1000<br><br>
    Example <br>
    John, 1,<br>
    Louise, 2<br>
    George, 3<br>`,
    複製範例:'Copy example',
    自訂名單:'My List ',
    帶有重複座號:'Contains duplicate seat numbers',
    座號範圍提示字:'Seat number should be set between 1-999',
    名單人數上限提示字:'The number of people on the list exceeds the limit of 100, please reduce the number',
    新增單筆名單人數達上限提示字:'The number of people on the list has reached the limit of 100. Cannot add more students.',
    自訂名單已達上限提示字:'The maximum of 3 preset lists have been reached',
    範例名單:`John, 1\nLouise, 2\nGeorge, 3\n`
  },
  gptBox:{
    Gpt工具:'GPT Tool Box',
    管理指令:'Manage Prompt',
    常用指令:'Commonly used Prompt',
    可輸入一段100字以內的指令:'You can enter a prompt within 100 characters...',
    總結大意:'Summarize the text ',
    整理同義字:'Organize the synonyms in the text ',
    等候提示字:'Please wait for GPT to finish answering this question before doing the next one!',
    等候關閉互動窗提示字:'Please wait for ChatGPT to finish answering this question before closing!',
    預設:'default',
    繼續:'Continue',
    當前語系指令:'Please use English.',
    確定:'OK',
    取消:'cancel',
    確定刪除後這個分類將無法復原:'After confirming the deletion, this category will not be restored!',
    刪除:'Delete',
    新增分類:"Add new category ",
    已達最大上限10個分類:"Maximum limit of 10 categories has been reached",
    暫無數據:"No prompt",
    emptyReply:"GPT is currently still generating, no response data is available yet. Please click 'Check Generation Result' in the top right corner, or try again!",
    queryResult:"Check Generation Result",
  }
}