export default {
      pageButton1: '智慧教室管理儀表板',
      pageButton2: '智慧學校管理表板',
      block1Title1: '當前啟動教室',
      block1Title2: '今日故障教室數',
      block1Title3: '待更新教室數',
      block1Title4: '帳號登入啟動課堂數',
      block1Title5: '總教室數',
      hotclass: '教室熱度',
      runing: '運行中',
      notInitiated: '未啟動',
      repair: '報修中',
      block2Title1: '教室開機數',
      block2Title2: '課堂數',
      block2Title3: '課堂參與人數',
      block2Title4: '今日課堂數量變化',
      block2Title5: '今日各年級課堂百分比',    
      block2SubTitle1: '今日數量/ 昨日數量',
      block2SubTitle2: '前7日平均',
      block2SubTitle3: '前30日平均',
      block2SubTitle4: '學期平均',
      block2SubTitle5: '今日使用時數',      
      block2SubTitle6: '前7日總數',
      block2SubTitle7: '前30日總數',
      block2SubTitle8: '學期總數',
      block3Title1: '今日智慧教室啟用類型',
      block3Title2: '智慧教室版本分布狀態',
      block3Title3: '各年級課堂總數',
      block3SubTitle1: '今日開課堂數',
      block3SubTitle2: '上週均課堂數',
      block3SubTitle3: '今日開課百分比',
      block4Title1: '累計課堂時數',
      block4Title2: '蘇格拉底議課APP授權狀態',
      block4Title3: '課堂作業系統',
      block4SubTitle1: '總計',    
      block4SubTitle2: '累計',
      block4SubTitle3: '最多課堂使用者',
      block4SubTitle4: '最長時數使用者',
      block4SubTitle5: '最高效率課堂使用者',
      block4SubTitle6: '今日開課數',
      block4SubTitle7: '累計開課數',
      block4SubTitle8: '最長課堂時數',
      block4SubTitle9: '累計課堂時數',
      block4SubTitle10: '最高單堂效率值',
      block4SubTitle11: '平均課堂效率值',
      grade1: '一年級',
      grade2: '二年級',
      grade3: '三年級',
      grade4: '四年級',
      grade5: '五年級',
      grade6: '六年級',
      grade7: '七年級',
      grade8: '八年級',
      grade9: '九年級',
      grade10: '十年級',
      grade11: '十一年級',
      grade12: '十二年級',
      selfpace: '書面問答',
      blankPageAdd: '新增檔案',
      pptImport: '匯入.pptx',
      powerclick: 'PowerClick',
      hteOpen: '開啟.hte',
      Other: '其他',
      today: '今天',
      lastweek: '前七天',
      text1: '通識教室',
      text2: '授權至',
      text3: '今日總課堂數',
  }
  