export default {
    text1:  'Total  Course(s)',
    text2:  'Teacher(s)',
    text3:  'Premium  Collection(s)',
    text4:  'Advanced  Collection(s)',
    text5:  'Classic  Collection(s)',
    text6:  'Today',
    text7:  'Week',
    text8:  'Month',
    text9:  'Semester',
    text10:  'Grade',
    text11:  'Subject',
    text12:  '1st  Grade',
    text13:  '2nd  Grade',
    text14:  '3rd  Grade',
    text15:  '4th  Grade',
    text16:  '5th  Grade',
    text17:  'Chinese',
    text18:  'Math',
    text19:  'English',
    text20:  'Science',
    text21:  'Art',
    text22:  'Other',
    text23:  'Interactive  Technology  (Grade)',
    text24:  'Interactive  Technology  (Subject)',
    text25:  'Music',
    text26:  'Testing',
    text27:  'Chinese',
    text28:  'English',
    text29:  'Art',
    text30:  'Scinece',
    text31:  'Class  Meeting',
    text32:  'Math',
    text33:  'Course(s)',
    text34:  'Other',
    text35:  'TII / PAI (Today)',
    text36:  'TII / PAI (Week)',
    text37:  'TII / PAI (SEM.)',
    text38:  'TII / PAI (All)',
    text39:  'Scoreboard',
    text40:  'Pick-out',
    text41:  'Timer',
    text42:  'Buzz-in',
    text43:  'Show  Chart',
    text44:  'Pop  Quiz',
    text45:  'Work  Comparison',
    text46:  'Push',
    text47:  'Flip  Cards',
    text48:  'Re-Check  Quiz',
    text49:  'Teaching Tech Distribution',
    text50:  'Total',
    text51:  'English',
    text52:  'Chinses',
    text53:  'English',
    text54:  'Math',
    text55:  'Science',
    text56:  'GE',
    text57:  'Teaching  Appilication  (Grade)',
    text58:  'Teaching  Appilication  (Subject)',
    text59:  'TII = Technological Interaction Index',
    text60:  'PAI = Pedagogical Application Index',
    text61:  'num. ',
    text62:  ' unit(s) in ',
    text63:  ' region from ',
    text64:  ' Grade',
}