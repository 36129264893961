import { post } from '@/filter/http'
import { jsFn } from '@/utils/jsFn'
import profile from '@/utils/profile'
var serverLocation = jsFn.isCN() ? 'cn' : 'global'

// let hostname = window.location.hostname
// if(hostname.indexOf('localhost') === 0 || hostname.indexOf('-rc') > 0) {
//     serverLocation += 'rc'
// }
var clientID = profile.clientID[serverLocation]
var apiUrl = profile.apiUrl[serverLocation]
console.log(apiUrl)
export default {
    // 取得快速登入CODE
    getCode: function(idToken, isTicket) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            let type = isTicket ? 'ticket' : 'code'
            let data = {
                grant_type: type,
                client_id: clientID,
                nonce: nonceStr,
                id_token: idToken
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res[type])
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 登入驗證
    login: function(item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            // 開頭有0要去0
            let re = /^[0-9]+$/
            if (re.test(item.id) && item.id.toString().indexOf(0) === 0) {
                item.id = item.id.substr(1)
            }
            let data = {
                client_id: clientID,
                grant_type: 'account',
                account: item.id,
                password: item.pass,
                nonce: nonceStr
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    let tData = jsFn.jwtDecode(res.id_token)
                    if (nonceStr === tData.nonce) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10001,
                            message: '錯誤登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 社群登入
    authLogin: function(item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            let data = {
                client_id: clientID,
                nonce: nonceStr,
                grant_type: item.grant_type,
                open_code: item.open_code,
                redirect_uri: item.redirect_uri
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    let tData = jsFn.jwtDecode(res.id_token)
                    if (nonceStr === tData.nonce) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10001,
                            message: '錯誤登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 快速登入
    codeLogin: function(code) {
        return new Promise((resolve, reject) => {
            let data = {
                client_id: clientID,
                grant_type: 'authorization_code',
                code: code
            }
            post(apiUrl + '/oauth2/token', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 登出
    logout: function(idToken, ids = []) {
        let data = {
            client_id: clientID,
            id_token: idToken,
            client_ids: ids // 要登出的id, 包括client id, 裝置id等
        }
        data.client_ids.push(clientID)
        return new Promise((resolve, reject) => {
            post(apiUrl + '/oauth2/logout', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(true)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 創建教室
    crtChannel: function(data) {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/channel/create', data).then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    },
    // 加入教室
    joinChannel: function(data) {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/channel/join', data).then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    },
    // 取得活動開放功能
    getContact: function(idToken) {
        return new Promise((resolve, reject) => {
            let data = {
                grant_type: 'get',
                product: 'benefits',
                id_token: idToken
            }
            post(apiUrl + '/oauth2/usersetting', data).then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    },
    // 取得個人的設定檔
    getSettings: function(idToken) {
        return new Promise((resolve, reject) => {
            let data = {
                grant_type: 'get',
                product: 'hiteachcc',
                id_token: idToken
            }
            post(apiUrl + '/oauth2/UserSetting', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve({error: 0, data: res})                    
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 設定個人設定檔
    setSettings: function(idToken, data) {
        return new Promise((resolve, reject) => {
            let tmp = {
                grant_type: 'update',
                id_token: idToken,
                data: data
            }
            post(apiUrl + '/oauth2/UserSetting', tmp).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(true)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 裝置註冊
    regDevice: function(idToken) {
        let data= {       
            client_name: 'HiTeachCC',
            id_token: idToken
        }
        return new Promise((resolve, reject) => {
            post(apiUrl + '/oauth2/deviceweb', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    setDefNoticeLang: function(idToken, lang) {
        let nonceStr = jsFn.getUUID()
        let data = {
            grant_type: 'lang',
            client_id: clientID,
            id_token: idToken,
            nonce: nonceStr,
            lang: lang
        }

        return new Promise((resolve, reject) => {    
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    createAITask: function(idToken, userPrompt) {
        let data= {       
            userPrompt: userPrompt,
            id_token: idToken
        }
        return new Promise((resolve, reject) => {
            post(apiUrl + '/Service/CreateAITask', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    getAITaskResult: function(idToken,taskId) {
        let data= {       
            taskId: taskId,
            id_token: idToken
        }
        return new Promise((resolve, reject) => {
            post(apiUrl + '/Service/GetAITaskResult', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    }, 

}
