import schoolMgmt from './schoolMgmt'
import classMgmt from './classMgmt'
import talMgmt from './talMgmt'
import menu from './menu'
import unit from './unit'
import common from './common'
export default {
  schoolMgmt,
  classMgmt,
  talMgmt,
  menu,
  unit,
  common,
  toolbox: {
    菜單: '選單',
    選擇: '選擇',
    回上一步: '回上一步',
    畫筆: '畫筆',
    圖形: '圖形',
    插入圖片: '道具箱',
    文字: '文字',
    橡皮擦: '橡皮擦',
    上一頁: '上一頁',
    新建一頁: '新建一頁',
    下一頁: '下一頁',
    缩放工具: '縮放工具',
    回到原始比例: '回到原始比例',
    IRS工具: '互動工具',
    推送圖片:'推送圖片',
    IRS即問即答: '即問即答',
    IRS填空: '文字題',
    IRS作品收集: '作品收集',
    IRS搶權: '搶權',
    挑人: '挑人',
    倒數計時: '倒數計時',
    重設為一般挑人模式:'重設為一般挑人模式',
    目前沒有動作可復原: '目前沒有動作可復原',
  },
  board: {
    前往:'前往',
    Gpt安裝提示標題:'安裝提示',
    GPT安裝提示字:'<p>您具備使用GPT工具之權限，建議可使Chrome或Edge的電腦版瀏覽器，<br>並前往下載安裝擴充套件，以支持GPT相關擴充功能，重新啟動登入即可使用!</p>',
    清空畫布提示字:'清空後無法復原，確定清空整個畫布? ',
    清空作品收集提示字:'清空後無法復原，確定清除目前已經繳交的作品?',
    網路斷線:'偵測到網路已經斷線，請檢查網路!',
    SignalR協議未正常連線:'部分通訊似乎斷線,建議重新整理！',
    閒置視窗文字:'閒置超過60分鐘，請點擊仍要使用，無動作將在1分鐘後自動登出',
    仍要使用:'仍要使用',
    文字工具:'文字便利貼工具',
    輸入文字:'輸入文字...',
    便利貼顏色:'便利貼顏色',
    確定:'確定',
    取消:'取消',
    選擇新增活動時之頁面內容:'請選擇新增活動的頁面內容',
    複製前一頁面:'複製前一頁內容',
    空白頁面:'空白新頁',
    螢幕大小基本需求說明:"不支援的裝置大小，為維持介面操作體驗，建議於電腦或平板電腦使用，且最低螢幕長寬解析度需求應至少達到 1024px*600px",
    歡迎使用HiTeachCC來進行互動: '歡迎使用HiTeach CC來進行互動',
    請選擇開始的動作: '請選擇開始的動作',
    開啟IES5所選教材:'開啟IES5所選教材',
    開啟PDF檔案: '使用PDF簡報',
    直接使用畫板: '使用空白畫板',
    參閱使用範例PDF:'參閱使用說明PDF',
    您的使用權限:'您的權限',
    學生連線數:'連線人數',
    作品收集數:'作品收集數',
    aigptEXP:'AIGPT 到期時間',
    權限到期:'專屬權益期間',
    '無法使用，已達您的權限作品收集數上限':'很抱歉，作品任務數已達上限',
    '偵測到有請求加入，但加入教室人數已達您的權限人數上限':'很抱歉，教室連線人數已達上限',
    '權益說明':'在首頁使用HiTA5 App掃碼登入，即可提升至50個連線數與6個作品任務數',
    IRS單選題選項設置: '單選題選項設置',
    全屏幕: '全螢幕',
    重啟課堂: '重啟課堂',
    結束登出: '結束登出',
    匯出PDF: '匯出PDF',
    匯出課堂記錄: '匯出課堂記錄',
    插入PDF:'插入PDF',
    自訂學生名單:'編輯自訂名單',
    PDFInit:{
      開啟PDF檔案: '使用PDF簡報',
      從:'從',
      到:'到',
      加到目前課堂文件:'匯入成課堂教材',
      第:'第',
      頁之後:'頁之後',
      使用限制提示:'使用限制提示',
      目前課堂文件頁數:'目前課堂教材頁數',
      加入頁數:'加入頁數',
      最多:'最多',
      頁:'頁',
      總頁數:'總頁數',
      使用限制提示警告:'超出使用限制，無法使用! 請調整匯入頁數後方可使用',
      PDF載入錯誤:'PDF載入錯誤'
    },
    PDFInsert:{
      從:'從',
      到:'到',
      加到目前課堂文件:'加到目前課堂教材',
      第:'第',
      頁之後:'頁之後',
      使用限制提示:'使用限制提示',
      目前課堂文件頁數:'目前課堂教材頁數',
      加入頁數:'加入頁數',
      最多:'最多',
      頁:'頁',
      總頁數:'總頁數',
      使用限制提示警告:'超出使用限制，無法使用! 請調整匯入頁數後方可使用',
      PDF載入錯誤:'PDF載入錯誤'
    },
    轉檔匯出中請稍候: 'PDF 轉檔匯出中請稍候!',
    匯出與上傳課堂數據到IES5:'匯出與上傳課堂數據到IES5',
    作品收集: '作品收集',
    選擇貼回之頁面:'選擇貼回之頁面',
    貼到本頁:'貼到本頁',
    貼到新增的空白頁:'貼到新增的空白頁',
    暫無數據: '暫無數據',
    請按鈕開始接收搶權:'請按鈕開始接收搶權',
    繼續搶權:'繼續搶權',
    等待搶權:'等待搶權',
    qrcodePop: {
      '請掃碼QRcode 用瀏覽器參與課堂互動': '請同學掃碼, 開瀏覽器參與課堂互動',
      已複製到剪貼簿: '已複製到剪貼簿',
      請掃碼QRcode: '請掃碼QRcode',
      用瀏覽器參與課堂互動: '用瀏覽器參與課堂互動',
      或直接在瀏覽器上輸入下列網址: '或直接在瀏覽器上輸入下列網址',
      '提示：許多手機掃碼工具內建的瀏覽器功能受限，建議掃碼後另開系統瀏覽器': '提示：許多手機掃碼工具內建的瀏覽器功能受限，建議掃碼後另開系統瀏覽器',
      'allowVisitor': '免登入',
      'allowSitin': '旁聽'
    },
    物件轉換中請稍候: '物件轉換中請稍候',
    請先勾選: '請先勾選',
    一次勾選貼回最多6張: '一次勾選貼回最多6張',
    请登陆: '請登入',
    上传成功: '上傳成功',
    '上传失败！请查看PDF页数是否超过20页 是否符合标准': '注意！PDF頁數已超出20頁，僅會轉換前20頁',
    正在制作画板: '正在產生頁面',
    制作画板制作成功: '頁面產生成功',
    上传格式有误: '上傳格式有誤',
    您上传的文件: '您上傳的檔案',
    '格式不正确, 请上传PDF格式': '格式不正確, 請上傳PDF格式',
    上传文件大小限制: '上傳檔案大小限制',
    '大小超过5M, 请上传不超过5M的文件': '檔案過大, 請上傳不超過5MB的檔案',
    正在制作中: '正在產生中',
    通讯连接已建立: '通訊連線已建立',
    '加入教室失败，请尝试重连!': '建立雲端教室失敗，請嘗試重整頁面!',
    '通讯连接失败，请尝试重连！': '通訊連線失敗，請嘗試重整頁面！',
    單選題已結束: '單選題已結束',
    文字題已結束: '文字題已結束',
    您的浏览器无法进入全屏模式: '您的瀏覽器無法進入全螢幕模式',
    '账号正在退出!': '正在登出！',
    本頁無Quiz活動: '本頁無互動活動',
    '正在努力加载，请稍等...': '處理中，請稍候...',
    '正在上传文件,请稍等...': '上傳檔案中,請稍候...',
    toolbox: {
      '此页已有题目，自動新增一页开启互动': '此頁已有活動，自動新增一頁開啟互動',
      目前已經是最後一頁: '目前已經是最後一頁',
      所選圖片過大:'所選圖片過大(應小於3MB)，請重新選擇！',
      畫筆: '畫筆',
      大小: '大小',
      顏色: '顏色',
      圖形: '圖形',
      邊框: '邊框',
      填充: '填充',
      比例: '比例',
      倒數: '倒數',
      計時: '計時',
      開始計時:'開始計時',
      暫停計時:'暫停計時',
      分:'分',
      秒:'秒',
      復位:'復位',
      重置:'歸零',
      記次:'記次',
      第:'第',
      條記錄:'條記錄',
      字體大小: '字體大小',
      IRS沒有學生提示訊息:'目前尚未有學生加入課堂, 請打開 QRcode 視窗讓學生掃描參與',
      通用畫筆:'通用畫筆',
      高光筆:'螢光筆',
      鐳射筆:'雷射筆',
      箭頭:'箭頭',
      直線:'直線',
      圓形:'圓形',
      橢圓:'橢圓',
      矩形:'矩形',
      三角形:'三角形',
      星形:'星形',
      刪除元素:'刪除元素',
      清空畫布:'清空畫布',
    },
    optionView: {
      統計翻牌自動結束: '統計翻牌後自動停止作答',
      匿名:'匿名',
      複製到剪貼簿: '複製到剪貼簿',
      餅圖: '圓餅圖',
      長條圖: '長條圖',
      翻牌: '翻牌',
      文字便利貼: '文字便利貼',
      文字雲:'文字雲',
      貼回舞台:'貼回舞台',
      停止作答: '停止作答',
      重新作答: '重新作答',
      單選題: '單選題',
      文字題: '文字題',
      '已全部複製到剪貼簿!': '已全部複製到剪貼簿!',
      '答案已重新開啟！請重新作答': '作答已重新開啟！請重新作答',
      設定答案成功:'設定答案成功',
      已移除答案:'已移除答案',
      設定無答案:'設定無答案',
      設定答案: '設定答案',
      無答案: '無答案',
      未作答: '未作答',
      重啟作答會清除所有已收到的數據: '重啟作答會清除所有已收到的資料',
      確定: '確定',
      取消: '取消',
      人:'人'
    },
    addImgBox:{
      檔案圖片:'插入圖片',
      選取本機檔案圖片:'選取圖片',
      剪貼簿:'剪貼簿',
      暫無數據:'暫無數據',
      取得複製到剪貼簿的資料:'取得剪貼簿的內容',
      轉換物件貼回:'貼回畫板',
      瀏覽器版本不支援:'您所使用的瀏覽器版本不支援此功能',
      瀏覽器不允許讀剪貼簿:'您所使用的瀏覽器不允許讀剪貼簿，請調整設定',
      素材庫:'素材庫',
      背景庫:'背景庫',
      發送文字:'推送文字',
      文字發送成功:'文字發送成功!',
      字串長度提示:'偵測到字串過長，僅顯示前500字！',
      確定:'確定',
      取消:'取消'
    },
    '暂无成员参与挑人。': '暫無成員參與挑人。',
    'Uploading': '上傳中',
    'ssoError': '您的帳號在其他設備登入，如果不是您的操作，請及時修改您的密碼。',
    objMenu:{
      刪除物件:'刪除',
      複製:'複製',
      移到最上層:'移到最上層',
      移到最下層:'移到最下層',
      將物件複製為圖片到剪貼簿:'複製物件圖片到剪貼簿',
      將物件複製為文字到剪貼簿:'複製文字到剪貼簿',
      已複製到剪貼簿: '已複製到剪貼簿!',
      瀏覽器版本不支援:'您所使用的瀏覽器版本不支援此功能',
      發送文字:'推送文字',
    },
    rightClickMenu:{
      從剪貼簿貼上:'從剪貼簿貼上'
    }
  },
  login: {
    formMsg: {
      error1: '取得Blob失敗',
      error2: '帳號或密碼錯誤',
      error3: '{state} 授權失敗',
      error4: '快速登入失敗',
      error5: '請使用HiTA 5掃描'
    },
    currentLang: '中文繁體',
    loginBox: {
      title: 'HiTeach CC',
      intro: '無硬體要求，無任何安裝HiTeach的設備均可與一體機電子白板互動教學',
      loginWord: '醍摩豆帳號登入',
      StudentEntrance: '學生/參與者 加入教室',
      headertitle: 'HiTeach CC',
      authLoginTitle: '或下列方式登入',
      loginId: 'Email / 手機帳號 / 用戶編號',
      password: '密碼',
      regist: '免費註冊',
      forgotPW: '忘記密碼',
      qrcode:{
        text1: '請使用',
        text2: 'HiTA 5',
        text3: '掃描可提升連線數',
        text4: '是否要掃碼登入?',
      }
    },
    footer: {
      create: '創建醍摩豆帳號'
    }
  },
  formConfigP: {
    input: '請輸入',
    select: '請選擇',
    country: '國家',
    province: '省份',
    city: '城市',
    school: '學校'
  },
  index: {
    login: '登入',
    register: '註冊',
    chooseRole: '選擇身份',
    exit: '退出',
    authSchool: '授權學校',
    currentRole: '當前身份',
    loginSuc: '登入成功'
  },
  //學情分析
  saindex: {
    index: '首頁',
    grade: '成績分析',
    droppoint: '落點分析',
    examination: '試題分析',
    knowledge: '知識點掌握'
  },
  sasidebar: {
    class: '選擇關注年級',
    classmsg: '請選擇年級',
    term: '選擇關注學年期',
    term: '請選擇學年期',
    compare: '選擇數據對比'
  },
  copyright: 'Copyright © 2024 網奕資訊科技 版權所有',
  error: {
    required: '不能為空',
    format: {
      default: '格式錯誤'
    }
  },
  remind: {
    title: '提醒',
    content: '當前瀏覽器不是Chrome瀏覽器，為了您更好的體檢，建議使用Chrome瀏覽器體驗！',
    toDownload: '去下載'
  },
  loadingMsg:{
    text1: '<b>初始化</b>......<br/><span class="text-primary">請等待...</span>',
    text2: '<b>登入中</b>......<br/><span class="text-primary">請等待...</span>'
  },
  report: {
    title: '課堂數據總表',
    activityName: '班級/課程',
    hostName: '授課者',
    meterialName: '教材',
    dateTime: '日期時間',
    attendCount: '出席人數',
    attendRate: '出席率',
    collateTaskCount: '發起任務數',
    collateCount: '收到作品數',
    pushCount: '推送資源數',
    totalInteractPoint: '總互動分',
    interactionCount: '互動題數',
    clientInteractionCount: '學生互動總數',
    clientInteractionAverge: '平均互動數',
    quizID: '題序',
    pageOrderStr: '頁次',
    score: '配分',
    correctAnswer: '正解',
    correctRateStr: '得分率',
    seatID: '座號',
    name: '姓名',
    attendState: '出席',
    attendState1: '已出席',
    attendState0: '未出席',
    taskCompleteCount: '作品數',
    interactScore: '互動分',
    answerList: '學生作答',
  },
  memberlist:{
    自由加入:'臨時加入動態生成',
    請選擇學生名單:'請選擇上課名單',
    創建自訂學生名單:'新建立預存的學生名單',
    使用IES5課程名單:'使用IES5課程名單',
    點選左側指定可加入的學生名單:'點選左側列表選擇預存的名單',
    點選Hi進行編輯:'如需編修預存名單，請按下一步進入系統後從Hi選單操作',
    下一步:'下一步',
    上一步:'上一步',
    編輯學生:'編輯學生',
    新增學生:'新增學生',
    姓名:'姓名',
    座號:'座號',
    座號重複:'座號重複',
    座號不可為空或輸入無法辨認的值:'座號不可為空或輸入無法辨認的值',
    姓名不可為空:'姓名不可為空',
    確定:'確定',
    取消:'取消',
    修改名單名稱:'修改名單名稱',
    表單名稱不可為空:'表單名稱不可為空',
    確定刪除後這個名單將無法復原:'確定刪除後這個名單將無法復原!',
    刪除:'刪除',
    自訂學生名單:'編輯預存名單',
    創建名單:'新建名單',
    暫無數據:'暫無數據',
    新建的學生名單:'新建的學生名單...',
    輸入名單資料:'輸入名單資料...(姓名,座號)',
    自動編號提示字:'無法辨識座號規則，系統自動編號',
    預覽:'預覽轉換',
    儲存預覽表格:'儲存名單',
    名單範例模板: `輸入名單小技巧<br><br>名單的格式為：
    姓名, 座號 <br>(座號可省略，如有填寫則須每列都填寫)<br><br>
    限制<br>
    姓名：字數長度50以內<br>
    座號：不能重複數字，應小於1000
     <br><br>
    範例 <br>
    王大明, 1<br>
    張小凡, 2<br>
    陳士軒, 3<br>`,
    複製範例:'複製範例',
    自訂名單:'我的名單',
    帶有重複座號:'帶有重複座號',
    座號範圍提示字:'座號應設置於1-999',
    名單人數上限提示字:'名單人數超過上限100人，請刪減人數',
    新增單筆名單人數達上限提示字:'名單人數達上限100人，無法新加',
    自訂名單已達上限提示字:'預存名單已達3個上限',
    範例名單:`王大明, 1\n張小凡, 2\n陳士軒, 3\n`
  },
  gptBox:{
    Gpt工具:'GPT工具箱',
    管理指令:'管理指令',
    常用指令:'常用指令',
    可輸入一段100字以內的指令:'可輸入一段100字以內的指令...',
    總結大意:'總結大意',
    整理同義字:'整理同義字',
    等候提示字:'請等候GPT回答完這個問題再做下一個!',
    等候關閉互動窗提示字:'請等候GPT回答完這個問題再關閉!',
    預設:'預設',
    繼續:'繼續',
    當前語系指令:'請用繁體中文，',
    確定:'確定',
    取消:'取消',
    確定刪除後這個分類將無法復原:'確定刪除後這個分類將無法復原!',
    刪除:'刪除',
    新增分類:"新增分類",
    已達最大上限10個分類:"已達最大上限10個分類",
    暫無數據:"暫無指令資料",
    emptyReply:"GPT目前仍在生成中，暫無回應數據，請按右上角「查詢生成結果」，或再試一次！",
    queryResult:"查詢生成結果",
  }
}
