import { jsFn } from '@/utils/jsFn'
import mqtt from 'mqtt'

const KEEPSTORAGE = 'KEEPSTORAGE'
const SETUPMQTT = 'SETUPMQTT'

export default {
    namespaced: true,
    state: {
        mqtt: {
            deviceInfo: {
                host: '',
                user_name: '',
                password: '',
                device_id: '',
                expires_in_mqtt: '',
                sub_direct_method: '',
                sub_device: '',
                pub_reply: '',
                pub_send: '',
                station: ''
            },
            callLogout: false
        },
        clinet: '',
        mqttIoTData:{ //結束課堂IoT資料, 此處僅方便儲存辨識, 最後送資料會再處理僅用逗號隔開, 不送propertyName
            channelNo:'',//0. Channel No
            userId:'', //1.ID
            schoolId:'', //2.預設學校簡碼（IES5優先, ID內部簡碼, 不一定有）
            isIES5:0,//3.開課是否使用IES課程 0/1
            isIES5Material:0, //4.課堂中取用IES5資源 this.classInfo.materialUrl  0/1
            isWebIRS:0, //5.課堂中有使用webirs(有學生binding)  0/1
            isHardWareIRS:'', //6.課堂中有使用硬體IRS:(CC給空值)
            isHaboard:'', //7.課堂中有使用Haboard: (CC給空值)
            isHiTA:'', //8.課堂中有使用Haboard: (CC給空值)
            classTime:0, //9.課堂時間(分鐘整數) 
            studentNum:0, //10.學生數(整數)
            Tvalue:'', //11.T綠燈: 0/1 都先給0
            IRSType_Cowork:0,//12.合作 (CCㄧ定是0)
            IRSType_Interact:0,//13.互動
            IRSType_CollateTask:0,//14.作品任務
            IRSType_Exam:0, //15.CC互動有設過答案就是1
            IRSType_Diversity:0, //16.差異化
            authType:0, //17.授權:(0: 無, 1: ID, 2: 機器, 3: ID+機器) 0不是IES5也沒權益的純登入 1:ID有包含權益, 2.IES5過來的CC就是機器授權  3.：1和2都有
            collateTaskCount:0, //18:任務數
            collateCount:0, //19:作品任務完成總數
            interactionCount:0, //20:題數
            clientInteractionCount:0, //21:互動總次數
            external_ip:'',//22.external_ip
            version:'',//23.version (ex. 5.0.21.0000 -> 500210000)
            isSok:'', // 24. 有送出小數據或SOK服務: 0/1
            IRSType_Mutual:'', //25.互評
            IRSType_CoworkNew:'',//26.協作
            isWordCloud:0, //27.wordCloud
            isClouDAS:'', //28.clouDAS
            isGpt:0, //29.GPT
            isExamIES5:'', //30. 課堂中有使用IES5測驗模式
            isExamPaper:'', //31. 課堂中有使用紙本測驗模式
            isExamExcel:'' //32. 課堂中有使用Excel測驗模式
        }
    },
    getters: {
        getCallLogout: state => state.mqtt.callLogout,
        getDeviceId: state => state.mqtt.deviceInfo.device_id,
    },
    mutations: {
        [KEEPSTORAGE](state, data) {
            let keys = Object.keys(data)
            keys.forEach(key => {
                state[key] = data[key]
            })
        },
        [SETUPMQTT](state, data) {
            state.mqtt = data
        },
    },
    actions: {
        /**
         * mqtt連線
         */
        connect(context) {
            let TMDid = jsFn.jwtDecode(sessionStorage.getItem('idToken'))
            let userid = TMDid.sub
            let deviceInfo = context.state.mqtt.deviceInfo
            let options = {
                connectTimeout: 4000, // 連線超時時間
                // 認證訊息
                clientId: deviceInfo.device_id,
                username: deviceInfo.user_name,
                password: deviceInfo.password,
                cleanSession: false,
                reconnectPeriod: 5000
            }
            context.state.client = mqtt.connect(deviceInfo.host, options)
            let nT = parseInt(Date.parse(new Date())).toString() // 用十碼計算

            context.state.client.on('connect', function() {
                console.log('mqtt 連線中...')
                // 訂閱主题
                context.state.client.subscribe([deviceInfo.sub_device, deviceInfo.sub_direct_method], function(err) {
                    if (!err) {
                        console.log('mqtt 主題訂閱成功...')
                    } else {
                        console.log('mqtt 主題訂閱失敗...')
                    }
                })

                context.state.client.publish(deviceInfo.pub_send, JSON.stringify({
                    time: nT,
                    did: deviceInfo.device_id,
                    type: 'login',
                    body: {
                        id: userid
                    }
                }), { qos: 1, retain: false }, function(err) {
                    if (!err) {
                        console.log('mqtt 登入成功...')
                    } else {
                        console.log(err)
                        console.log('mqtt 登入失敗...')
                    }
                })
            })

            context.state.client.on('reconnect', function() {
                console.log('Reconnecting...')
            })

            context.state.client.on('error', (error) => {
                console.log('连接失败:', error)
            })

            context.state.client.on('close', function() {
                console.log('Disconnected')
            })

            context.state.client.on('offline', function() {
                console.log('offline')
            })

            context.state.client.on('message', (topic, msg) => {
                let data = JSON.parse(msg)
                if (data.command === 'logout') {
                    // 執行登出
                    context.state.mqtt.callLogout = true
                    console.log('mqtt 準備登出...')
                }
            })
        },
        logout(context) {
            context.state.client.end()
            context.state.client = ''
            console.log('mqtt 已登出...')
        },
        setDeviceInfo(context, data) {
            let temp = context.state.mqtt
            temp.deviceInfo = {
                host: data.host,
                user_name: data.username,
                password: data.password,
                device_id: data.device_id,
                expires_in_mqtt: data.expires_in_mqtt,
                sub_direct_method: data.sub_direct_method,
                sub_device: data.sub_device,
                pub_reply: data.pub_reply,
                pub_send: data.pub_send,
                station: data.station
            }
            context.commit(SETUPMQTT, temp)
            context.dispatch('saveState') // 存到sessionStorage
        },
        /**
         * NOTE: 維持 vuex state 的資訊
        */
        keepState(context) {
            if (!jsFn.isEmpty(sessionStorage.getItem('mqttData'))) {
                let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('mqttData'), 'utf-8'))
                context.commit(KEEPSTORAGE, data)
            }
        },
        /**
         * NOTE: 儲存現在 state 的資訊
        */
        saveState(context) {
            let saveData = {
                mqtt: context.state.mqtt
            }
            sessionStorage.setItem('mqttData', encodeURIComponent(JSON.stringify(saveData), 'utf-8'))
        },
        saveMqttIoTsessionStorage(context){
            sessionStorage.setItem('mqttIoTData', JSON.stringify(context.state.mqttIoTData))
        },
        sendIoTReport(context){
            let nT = parseInt(Date.parse(new Date())).toString() // 用十碼計算
            let deviceInfo = context.state.mqtt.deviceInfo
            let finalIoTData=[]

            Object.keys(context.state.mqttIoTData).forEach(key => {
                finalIoTData.push(context.state.mqttIoTData[key])
            })
            
            let finalIoTStringData=finalIoTData.join()

            context.state.client.publish(deviceInfo.pub_send, JSON.stringify({
                time: nT,
                did: deviceInfo.device_id,
                type: 'teachingdata',
                body: {
                    data : finalIoTStringData
                }
            }), { qos: 1, retain: false }, function(err) {
                if (!err) {
                    console.log('mqtt sendIoTReport成功...')
                } else {
                    console.log(err)
                    console.log('mqtt sendIoTReport失敗...')
                }
            })
        
        }
    }
}
