import Vue from 'vue'

import './styles/quasar.styl'
import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v3/mdi-v3.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import {
    Quasar,
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QBtnDropdown,
    QDialog,
    QCard,
    QAvatar,
    QInput,
    QPopupProxy,
    ClosePopup,
    QSlider,
    QColor,
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QFab,
    QFabAction,
    QKnob,
    QTabs,
    QTab,
    QRouteTab,
    QSeparator,
    QTabPanels,
    QTabPanel,
    Loading,
    QUploader,
    QRadio,
    QCardSection,
    QCardActions,
    QSpace,
    QCheckbox,
    QCircularProgress,
    QBtnToggle,
    QLinearProgress,
    QToggle,
    QInnerLoading,
    QBtnGroup,
    Dialog
} from 'quasar'

Vue.use(Quasar, {
    config: {},
    components: {
        QLayout,
        QHeader,
        QDrawer,
        QPageContainer,
        QPage,
        QToolbar,
        QToolbarTitle,
        QBtn,
        QIcon,
        QList,
        QItem,
        QItemSection,
        QItemLabel,
        QBtnDropdown,
        QDialog,
        QCard,
        QAvatar,
        QInput,
        QPopupProxy,
        ClosePopup,
        QSlider,
        QColor,
        QCarousel,
        QCarouselControl,
        QCarouselSlide,
        QFab,
        QFabAction,
        QKnob,
        QTabs,
        QTab,
        QRouteTab,
        QSeparator,
        QTabPanels,
        QTabPanel,
        Loading,
        QUploader,
        QRadio,
        QCardSection,
        QCardActions,
        QSpace,
        QCheckbox,
        QCircularProgress,
        QBtnToggle,
        QLinearProgress,
        QToggle,
        QInnerLoading,
        QBtnGroup
    },
    plugins: {
        Loading,
        Dialog
    },
    directives: {
        ClosePopup
    }
})
