export default {
    apiUrl: {
        global: 'https://api2.teammodel.net',
        cn: 'https://api2.teammodel.cn',
        // 讓RC站也連正式站，有需要再改
        globalrc: 'https://api2.teammodel.net',
        cnrc: 'https://api2-rc.teammodel.cn'
    },
    ies5Api: {
        global: 'https://www.teammodel.net',
        cn: 'https://www.teammodel.cn',
        globaltest: 'https://ies5-rc.teammodel.net',
        cntest: 'https://ies5-rc.teammodel.cn'
    },
    clientID: {
        global: '118d2d4d-32a3-44c0-808a-792ca73d3706',
        cn: '227082f4-8db0-4517-b281-93dba9428bc7',
        globalrc: '118d2d4d-32a3-44c0-808a-792ca73d3706',
        cnrc: '227082f4-8db0-4517-b281-93dba9428bc7'
    },
    domainUrl: {
        global: 'https://hiteachcc.teammodel.net',
        cn: 'https://hiteachcc.teammodel.cn',
        globalrc: 'https://hiteachcc-rc.teammodel.net',
        cnrc: 'https://hiteachcc-rc.teammodel.cn'
    },
    accountUrl: {
        global: 'https://account.teammodel.net',
        cn: 'https://account.teammodel.cn'
    },
    irs5Url: {
        global: 'https://irs5.teammodel.net',
        cn: 'https://irs5.teammodel.cn'
    },
    BBUrl: {
        global: 'https://bb.teammodel.net',
        cn: 'https://bb.teammodel.cn',
        globalrc: 'https://bb-rc.teammodel.net',
    },
    communityUrl: function(provider, redirectUri) {
        let result
        switch (provider) {
            case 'facebook':
                result = 'https://www.facebook.com/v7.0/dialog/oauth?scope=public_profile,email&redirect_uri=' + redirectUri + '&response_type=code&client_id=1382704845111595&display=popup&state=facebook'
                break
            case 'google':
                result = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&redirect_uri=' + redirectUri + '&response_type=code&client_id=30604537950-2e6eojbqt5k1kjg43jdvdq6g3gp0eo33.apps.googleusercontent.com&display=popup&state=google'
                break
            case 'wechat':
                result = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxd88617b770d4a9f4&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_login&state=wechat#wechat_redirect'
                break
            case 'ding':
                result = 'https://oapi.dingtalk.com/connect/qrconnect?appid=dingwjrrgdgejvgx5pbm&response_type=code&scope=snsapi_login&state=ding&redirect_uri=' + redirectUri
                break
            case 'educloudtw':
                // 教育雲正式站
                result = 'https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=' + redirectUri + '&scope=openid+email+profile&state=educloudtw&nonce=aaaaa'
                // 教育雲測試站 
                // result = 'https://oidc.sso.edu.tw/oidc/v1/azp?response_type=code&client_id=9d8bfb91c18efafd423433d6d21ca2f3&redirect_uri=' + redirectUri + '&scope=openid+email+profile&state=educloudtw&nonce=aaaaa'
            break
        }
        return result
    },
    getApiUrl: function() {
        if (process.env.NODE_ENV === 'development') {
            return this.apiUrl.global
        } else {
            let hostname = window.location.hostname
            let isCN = hostname.substr(hostname.length - 3) === '.cn'
            let serverLocation = isCN ? 'cn' : 'global'
            if(hostname.indexOf('localhost') === 0 || hostname.indexOf('-rc') > 0) {
                serverLocation += 'rc'
            }
            return this.apiUrl[serverLocation]
        }
    }
}
