import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../locale'
import funcs from './module/funcs'
import preferences from './module/preferences'
import classInfo from './module/classInfo'
import mqtt from './module/mqtt'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stage: null,
    layer: null,
    mode: '',
    uploadUrl: '',
    newboardUrl: '/board.pdf',
    initIsWhiteboard: true, //pdf模式  上传还是白板
    // newboardUrl: 'https://teammodelstorage.blob.core.chinacloudapi.cn/hiteachcc/pdffiles/20200708/1280687394751905792/白板PDF_20200708101723.pdf',
    //newboardUrl:'https://teammodelstorage.blob.core.chinacloudapi.cn/hiteachcc/pdffiles%2F20200708%2F1280750011705065472%2FMS-PPTX.pdf',
    // uploadUrl:'',
    imgArr: [],
    imgArrOld: [],
    msgBody:[],
    pdfOringinalImgs: [], //存放原圖連結
    pdfOringinalImgsIdArr: [], //存放原圖id
    addNew: false,
    copyPage: false,
    deletePage: false, //刪除一頁
    deletePageNum: 0,
    pen: null, //画笔方法
    textSize: 20,
    textColor: 'red',
    penType: 'pencil-alt',
    penType2: 'pencil-alt',
    strokeColor: 'red',
    strokeWidth: 1,
    laserColor: '#B2028A',
    laserStrokeWidth: 5,
    shapeTypeIcon: 'shapes',
    shapeType: 'long-arrow-alt-right',
    shapeColor: '',
    shapeBorderColor: 'red',
    ques: '',
    zoom: 1, //放大
    zoomType: 'hand',
    zoomMode: 'click', //click or bar 操控放大
    eraser: 'eraser',
    question: {
      //提问的选项
      submit: '0',
      text: '1',
      textselectA: '11',
      textselectB: '12',
      textselectC: '13',
      textselectD: '14'
    },
    irsBack: [
      //{ id: "匿名", studentName: "匿名", sendTime: "2020-01-08 16:02", value: "哈喽，示例" },
    ],
    pdftext: [], //当前PDF内的文字收集内容
    totalcontent: [], //所有页面IRS与文字模式内容
    checkvalue: 'checkbtn', //选择按钮的值
    students: [
      // {
      //   sort:1,
      //   id:'123',
      //   studentName:'王明',
      //   sendTime:'123456546',
      //   headImg:'https://cdn.quasar.dev/img/avatar.png',
      //   singScore:10,
      //   totalScore:20,
      //   option:'A',
      // },
      // {
      //   sort:2,
      //   id:'123',
      //   studentName:'张明2',
      //   sendTime:'123456546',
      //   headImg:'https://cdn.quasar.dev/img/avatar.png',
      //   singScore:10,
      //   totalScore:20,
      //   option:'A',
      // },
      // {
      //   sort:3,
      //   id:'123',
      //   studentName:'李明3',
      //   sendTime:'123456546',
      //   headImg:'https://cdn.quasar.dev/img/avatar.png',
      //   singScore:10,
      //   totalScore:20,
      //   option:'A',
      // },
    ],
    answernum: '0',
    noanswernum: '0',
    rightanswernum: '0',
    erroranswernum: '0',
    carousel: '',
    carouselSlide: 1, // 当前页数
    totalpage: '1',
    oldSlide: 1,
    answerstatus: 0,
    dynamicanswerstatus: 0,
    echartbaranswer: '',
    answervalue: '',
    buzzInStudents: [], // 存放搶權的學生列表
    userLoginStatus: '',
    userLogin: {
      // 用户信息，刷新后保持登录状态
      token: '',
      userdeviceid: '',
      userloginBack: '',
      usermqtt: '',
      userClassNum: ''
    },
    stickyNoteDatas: [],
    // 关联CSS的value
    cssvalue: '0',
    // IRS开关值
    IRSswitch: true,
    // 柱状图默认选项呈现
    AnswerSelect: ['A', 'B', 'C', 'D'],
    AnswerNumselect: ['1', '2', '3', '4'],
    // PPTX解析
    fileAnalysis: '',
    AfterParsing: {},
    // 作品收集
    worksArr: [],
    // 作品收集开关
    workswitch: false,
    irsModel: '',
    workNum: 0, //目前作品收集的編號
    currentWorkNum: 1,
    beforeSaveStageGenID: false,
    collateCheckTable: [],
    currentEditTextNode: '',
    isFilterCheckwork: false,
    isSortWork: false,
    currentState: '',
    isOnlyRefresh: false, //純點重新整理時拉回資料,
    optionViewHeight: 240,
    isShowCard: false,
    isLightPieChart: false,
    clickPdfnumToOpenSideBar: false,
    isRenderingPDFNow: false,
    isExportPDFNow: false,
    currentPage: 0,
    pdfLimit: 20,
    touchHideMenu: false,
    isEditingTextNow: false, //是否正在編輯文字
    isShowAskCopyPage: false,
    isShowAskCopyPageIRSName: '',
    showtextInuptCard: false,
    showPDFInsert: false, //開課後插入PDF的選單
    showPDFInitPageSelect: false, //開頭上傳PDF的頁數選單
    PDFInitRenderInfo: {
      fromIndex: 0,
      endIndex: 0,
      currentRenderPage: 0,
      totalRenderPage: 0,
      renderCount: 1
    },
    hideStudentName: false, //匿名
    timerIsStart: false,
    showAddImgBox: false, //圖片工具箱,
    candidates_turnTable: [],
    alreadyPick_turnTable: [],
    candidates_turnTable_InSmartOriginal: [],
    candidates_turnTable_InSmart: [],
    alreadyPick_turnTable_InSmart: [],
    turnTableＭode:'Normal', //Normal or Smart 一般或智慧挑人
    turnTableInSmartAction:{PickupType:'',PickupOption:''}, //智慧挑人紀錄給timeLineEvent的動作

    //设定答案選項
    currentAnsOption: 'En',
    //设定答案状态值
    answerpattern: 'letter',
    alreadySetIRSBlockSize: false,
    tempIRSBlockSize: '20',
    tempPenstate: [
      // 各種筆刷"控制中不斷改變"的暫存設定
      {
        type: 'pencilAlt',
        color: 'red',
        size: '1'
      },
      {
        type: 'highlighter',
        color: 'orange',
        size: '4'
      },
      {
        type: 'magic',
        color: '#0000ff',
        size: '2'
      }
    ],
    tempGptActionList:[{ value: i18n.t('gptBox["總結大意"]') }, { value: i18n.t('gptBox["整理同義字"]') },{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""}],
    startTime: 0,
    elapsedTime: 0,
    timeLineEvents: [],
    version: 'v5.0.240730.1'
  },
  mutations: {},
  actions: {
    increment(context, payload) {
      context.commit('increment', payload)
    }
  },
  userinformation: {
    deviceId: '',
    classNum: '',
    TWtoken: '',
    mqtts: '',
    loginBack: '',
    serviceUrl: ''
  },
  modules: {
    funcs,
    preferences,
    classInfo,
    mqtt
  }
})