export default {
  pageButton1: 'Smarter Classroom IoT Dashboard',
  pageButton2: 'Smarter School Management',
  block1Title1: 'Classroom Usage (D)',
  block1Title2: 'Malfunction',
  block1Title3: 'Inactivation',
  block1Title4: 'ID Activation',
  block1Title5: 'Total',
  hotclass: 'Trends',
  runing: 'activated',
  notInitiated: 'inactivated',
  repair: 'repaired',
  block2Title1: 'HiTeach Usage',
  block2Title2: 'Course Usage',
  block2Title3: 'Participants',
  block2Title4: 'Course Variety (D)',
  block2Title5: 'Course Percentage (D)',
  block2SubTitle1: 'Yday/DBY',
  block2SubTitle2: 'Week Avg',
  block2SubTitle3: 'Month Avg',
  block2SubTitle4: 'SEM. Avg',
  block2SubTitle5: 'Hour(s)',
  block2SubTitle6: 'Week Ttl',
  block2SubTitle7: 'Month Ttl',
  block2SubTitle8: 'SEM. Ttl',
  block3Title1: 'HiTeach Software',
  block3Title2: 'Course Percentage in grades',
  block3Title3: 'Course Status in grades',
  block3SubTitle1: 'Course(s) (D)',
  block3SubTitle2: 'Avg courses (Last week)',
  block3SubTitle3: 'Course Percentage',
  block4Title1: 'Accumulation Hour(s)',
  block4Title2: '蘇格拉底議課APP授權狀態',
  block4Title3: 'Operation System',
  block4SubTitle1: 'Total',
  block4SubTitle2: 'Accumulation',
  block4SubTitle3: 'The Most Times User',
  block4SubTitle4: 'The Longest Time User',
  block4SubTitle5: 'The Highest Efficient User',
  block4SubTitle6: 'Today\'s Course(s)',
  block4SubTitle7: ' Total Course(s)',
  block4SubTitle8: 'Longest Course',
  block4SubTitle9: 'Total Time',
  block4SubTitle10: 'Course Efficiency',
  block4SubTitle11: 'Course Efficiency Avg',
  grade1: '1st Grade',
  grade2: '2nd Grade',
  grade3: '3rd Grade',
  grade4: '4th Grade',
  grade5: '5th Grade',
  grade6: '6th Grade',
  grade7: '7th Grade',
  grade8: '8th Grade',
  grade9: '9th Grade',
  grade10: '10th Grade',
  grade11: '11th Grade',
  grade12: '12th Grade',
  blankPageAdd: 'Open File',
  selfpace: 'Q&A',
  pptImport: '.pptx',
  powerclick: 'PowerClick',
  hteOpen: '.hte',
  Other: 'Other',
  today: 'Today',
  lastweek: '7 days ago',
  text1: 'GE Classroom(s)',
  text2: 'S/N Expiration',
  text3: 'Total Courses (D)',
  }
  