import CryptoJS from 'crypto-js'

export default {// 加密
  encrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcdefgabcdefg12'
    const key = CryptoJS.enc.Utf8.parse(keyStr) // Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: key, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString()
  },
  // 解密
  decrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcdefgabcdefg12'
    const key = CryptoJS.enc.Utf8.parse(keyStr);// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    const decrypt = CryptoJS.AES.decrypt(word, key, {iv: key, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7})
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  },

};