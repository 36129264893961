import { post } from '@/filter/http'
import { jsFn } from '@/utils/jsFn'
import profile from '@/utils/profile'

var serverLocation = jsFn.isCN() ? 'cn' : 'global'
let hostname = window.location.hostname
if(hostname.indexOf('localhost') === 0) {
    serverLocation = 'globalrc'
}

var apiUrl = profile.BBUrl[serverLocation]


export default {
    // 取得個人授權
    getTMIDService: function() {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/hiteach/get-tmid-service', {}).then((res) => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    }
}
