export default {
    text1: '收课总数',
    text2: '教师数',
    text3: '特级典藏数',
    text4: '高级典藏数',
    text5: '一级典藏数',
    text6: '本日',
    text7: '近七日',
    text8: '近30日',
    text9: '本学期',
    text10: '年级',
    text11: '科目',
    text12: '一年级',
    text13: '二年级',
    text14: '三年级',
    text15: '四年级',
    text16: '五年级',
    text17: '语文',
    text18: '数学',
    text19: '英文',
    text20: '科学',
    text21: '艺术',
    text22: '其他',
    text23: '科技互动指数关系图(年级)',    
    text24: '科技互动指数关系图(科目)',   
    text25: '音乐',   
    text26: '软件测试',   
    text27: '语文',   
    text28: '英语',   
    text29: '美术',   
    text30: '科学',   
    text31: '班会',   
    text32: '数学',   
    text33: '开学课程',   
    text34: '其他',
    text35: '本日互动/教法',
    text36: '30日互动/教法',
    text37: '本学期互动/教法',
    text38: '所有互动/教法',
    text39: '计分板',
    text40: '挑人',
    text41: '计时器',
    text42: '抢权',
    text43: '统计图',
    text44: '即问即答',
    text45: '作品比较',
    text46: '推送',
    text47: '翻牌',
    text48: '二次作答',
    text49: '常用科技分布图',
    text50: '总量',
    text52: '语文',
    text53: '英语',
    text54: '数学',
    text55: '科学',
    text56: '综合',
    text57: '教法应用指数关系图(年级)',
    text58: '教法应用指数关系图(科目)',    
    text59:  '互动 = 技术互动指数',
    text60:  '教法 = 教学应用指数',
    text61:  '裡，',
    text62:  '区间的一共有',
    text63:  '人',
    text64:  '年级',
}