import schoolMgmt from './schoolMgmt'
import classMgmt from './classMgmt'
import talMgmt from './talMgmt'
import menu from './menu'
import unit from './unit'
import common from './common'
export default {
  classMgmt,
  schoolMgmt,
  talMgmt,
  menu,
  unit,
  common,
  toolbox: {
    菜單: '菜单',
    選擇: '选择',
    回上一步: '回上一步',
    畫筆: '画笔',
    圖形: '图形',
    插入圖片: '百宝箱',
    文字: '文字',
    橡皮擦: '橡皮擦',
    上一頁: '上一页',
    新建一頁: '新建一页',
    下一頁: '下一页',
    缩放工具:'缩放平移工具',
    回到原始比例: '回到原始比例',
    IRS工具: '互动工具',
    推送圖片:'推送图片',
    IRS即問即答: '即问即答',
    IRS填空: '文字填空',
    IRS作品收集: '作品收集',
    IRS搶權: '抢权',
    挑人: '挑人',
    倒數計時: '倒数计时',
    重設為一般挑人模式:'重设为一般挑人模式',
    目前沒有動作可復原: '目前没有动作可复原'
  },
  board: {
    前往:'前往',
    Gpt安裝提示標題:'安装提示',
    GPT安裝提示字:'<p>您具备使用GPT工具之权限，建议可使用chrome或edge的电脑版浏览器，<br>并前往下载安装扩充套件，以支持GPT相关扩充功能，重新启动登入即可使用!</p>',
    清空畫布提示字:'清空后无法复原，确定清空整个画布? ',
    清空作品收集提示字:'清空后无法复原，确定清除目前已经缴交的作品?',
    網路斷線:'侦测到网路已经断线，请检查网路!',
    SignalR協議未正常連線:'部分通讯似乎断线,建议重新整理！',
    閒置視窗文字:'闲置超过60分钟，请点击仍要使用，无动作将在1分钟后自动登出',
    仍要使用:'仍要使用',
    文字工具:'文字便利贴工具',
    輸入文字:'输入文字...',
    便利貼顏色:'便利贴颜色',
    確定:'确定',
    取消:'取消',
    選擇新增活動時之頁面內容:'请选择新添加活动的页面内容',
    複製前一頁面:'复制前一頁页内容',
    空白頁面:'空白页',
    螢幕大小基本需求說明:"不支援的装置大小，为维持介面操作体验，建议于电脑或平板电脑使用，且最低萤幕长宽解析度需求应至少达到 1024x600",
    歡迎使用HiTeachCC來進行互動: '欢迎使用HiTeachCC来进行互动',
    請選擇開始的動作: '请选择开始的动作',
    開啟IES5所選教材:'开启IES5所选教材',
    開啟PDF檔案: '开启PDF档案',
    直接使用畫板: '使用空白画板',
    參閱使用範例PDF:'参阅使用范例PDF',
    您的使用權限:'您的使用权限',
    學生連線數:'学生连接数',
    作品收集數:'作品收集数',
    aigptEXP:'AIGPT 到期时间',
    權限到期:'权益到期时间',
    '無法使用，已達您的權限作品收集數上限':'对不起，作品任务数已达上限',
    '偵測到有請求加入，但加入教室人數已達您的權限人數上限':'对不起，学生连接数已达上限',
    '權益說明':'在首頁使用HiTA5 App扫码登入，即可提升至50个学生连接数与6个作品任务数',
    IRS單選題選項設置: 'IRS单选题选项设置',
    全屏幕: '全屏幕',
    重啟課堂: '重启课堂',
    結束登出: '结束登出',
    匯出PDF: '导出PDF',
    匯出課堂記錄: '导出课堂记录',
    插入PDF:'插入PDF',
    自訂學生名單:'自订学生名单',
    PDFInit:{
      開啟PDF檔案: '开启PDF档案',
      從:'从',
      到:'到',
      加到目前課堂文件:'加到目前课堂文件',
      第:'第',
      頁之後:'页之后',
      使用限制提示:'使用限制提示',
      目前課堂文件頁數:'目前课堂文件页数',
      加入頁數:'加入页数',
      最多:'最多',
      頁:'页',
      總頁數:'总页数',
      使用限制提示警告:'超出使用限制，无法使用! 请调整页数配置后方可使用',
      PDF載入錯誤:'PDF载入错误'
    },
    PDFInsert:{
      從:'从',
      到:'到',
      加到目前課堂文件:'加到目前课堂文件',
      第:'第',
      頁之後:'页之后',
      使用限制提示:'使用限制提示',
      目前課堂文件頁數:'目前课堂文件页数',
      加入頁數:'加入页数',
      最多:'最多',
      頁:'页',
      總頁數:'总页数',
      使用限制提示警告:'超出使用限制，无法使用! 请调整页数配置后方可使用',
      PDF載入錯誤:'PDF载入错误'
    },
    轉檔匯出中請稍候: 'PDF 转档导出中请稍等!',
    匯出與上傳課堂數據到IES5:'导出与上传课堂数据到IES5',
    作品收集: '作品收集',
    選擇貼回之頁面:'选择贴回之页面',
    貼到本頁:'贴到本页',
    貼到新增的空白頁:'贴到新增的空白页',
    暫無數據: '暂无数据',
    請按鈕開始接收搶權:'请按钮开始接收抢权',
    繼續搶權:'继续抢权',
    等待搶權:'等待抢权',
    qrcodePop: {
      '請掃碼QRcode 用瀏覽器參與課堂互動': '请学生扫码用浏览器参与课堂互动',
      已複製到剪貼簿: '已复制到剪贴板',
      請掃碼QRcode: '请扫码',
      用瀏覽器參與課堂互動: '用浏览器参与课堂互动',
      或直接在瀏覽器上輸入下列網址: '或直接在浏览器上输入下列网址',
      '提示：許多手機掃碼工具內建的瀏覽器功能受限，建議掃碼後另開系統瀏覽器': '提示：很多手机扫码工具内置浏览器功能受限，建议扫码后另开系统浏览器',
      'allowVisitor': '免登录',
      'allowSitin': '旁听'
    },
    物件轉換中請稍候: '转换中请稍等',
    請先勾選: '请先勾选',
    一次勾選貼回最多6張: '一次最多贴回6张',
    请登陆: '請登入',
    上传成功: '上传成功',
    '上传失败！请查看PDF页数是否超过20页 是否符合标准': '对不起，PDF页数已超出20页，仅渲染前20页',
    正在制作画板: '正在制作画板',
    制作画板制作成功: '画板制作成功',
    上传格式有误: '上传格式有误',
    您上传的文件: '您上传的文件',
    '格式不正确, 请上传PDF格式': '格式不正确，请上传PDF格式',
    上传文件大小限制: '上传文件大小限制',
    '大小超过5M, 请上传不超过5M的文件': '大小超过5MB, 请上传不超过5MB的文件',
    正在制作中: '正在制作中',
    通讯连接已建立: '通讯连接已建立',
    '加入教室失败，请尝试重连!': '加入教室失败，请尝试重连!',
    '通讯连接失败，请尝试重连！': '通讯连接失败，请尝试重连！',
    單選題已結束: '单选题已结束',
    文字題已結束: '文字题已结束',
    您的浏览器无法进入全屏模式: '您的浏览器无法进入全屏模式',
    '账号正在退出!': '正在退出！',
    本頁無Quiz活動: '本页无互动活动',
    '正在努力加载，请稍等...': '正在努力加载，请稍等...',
    '正在上传文件,请稍等...': '正在上传文件,请稍等...',
    toolbox: {
      '此页已有题目，自動新增一页开启互动': '此页已有题目，自动添加新页开启互动',
      目前已經是最後一頁: '目前已经是最后一页',
      所選圖片過大:'所选图片过大(应小于3MB)，请重新选择！',
      畫筆: '画笔',
      大小: '大小',
      顏色: '颜色',
      圖形: '图形',
      邊框: '边框',
      填充: '填充',
      比例: '比例',
      倒數: '倒数',
      計時: '计时',
      開始計時:'开始计时',
      暫停計時:'暂停计时',
      分:'分',
      秒:'秒',
      復位:'复位',
      重置:'重置',
      記次:'记次',
      第:'第',
      條記錄:'条记录',
      字體大小: '字体大小',
      IRS沒有學生提示訊息:'还没有学生加入课堂，请打开二维码窗口让学生扫描参与',
      通用畫筆:'通用画笔',
      高光筆:'高光笔',
      鐳射筆:'镭射笔',
      箭頭:'箭头',
      直線:'直线',
      圓形:'圆形',
      橢圓:'椭圆',
      矩形:'矩形',
      三角形:'三角形',
      星形:'星形',
      刪除元素:'删除元素',
      清空畫布:'清空画布',
    },
    optionView: {
      統計翻牌自動結束: '统计翻牌后自动停止接收',
      匿名:'匿名',
      複製到剪貼簿: '复制到剪贴板',
      餅圖: '饼图',
      長條圖: '长条图',
      翻牌: '翻牌',
      文字便利貼: '文字便利贴',
      文字雲:'文字云',
      貼回舞台:'贴回舞台',
      停止作答: '停止作答',
      重新作答: '重新作答',
      單選題: '单选题',
      文字題: '文字题',
      '已全部複製到剪貼簿!': '已全部复制到剪贴板!',
      '答案已重新開啟！請重新作答': '作答已重新开启！请再次回答',
      設定答案成功:'设定答案成功',
      已移除答案:'已移除答案',
      設定無答案:'设定无答案',
      設定答案: '设定答案',
      無答案: '无答案',
      未作答: '未作答',
      重啟作答會清除所有已收到的數據: '重启作答会清除所有已收到的数据',
      確定: '确定',
      取消: '取消',
      人:'人',

    },
    addImgBox:{
      檔案圖片:'插入图片',
      選取本機檔案圖片:'选取图片',
      剪貼簿:'剪贴簿',
      暫無數據:'暂无数据',
      取得複製到剪貼簿的資料:'取得剪贴板的内容',
      轉換物件貼回:'贴回画板',
      瀏覽器版本不支援:'您所使用的浏览器版本不支援此功能',
      瀏覽器不允許讀剪貼簿:'您所使用的浏览器不允许读剪贴簿，请调整设定',
      素材庫:'素材库',
      背景庫:'背景库',
      發送文字:'推送文字',
      文字發送成功:'文字发送成功!',
      字串長度提示:'侦测到字串过长，仅显示前500字！',
      確定:'确定',
      取消:'取消'
    },
    '暂无成员参与挑人。': '暂无成员参与挑人。',
    'Uploading': '上传中',
    'ssoError': '您的帐户已在另一台设备上登录。如果不是您操作，请及时修改密码。',
    objMenu:{
      刪除物件:'删除',
      複製:'复制',
      移到最上層:'移到最上层',
      移到最下層:'移到最下层',
      將物件複製為圖片到剪貼簿:'复制物件图片到剪贴板',
      將物件複製為文字到剪貼簿:'复制文字到剪贴板',
      已複製到剪貼簿: '已复制到剪贴板!',
      瀏覽器版本不支援:'您所使用的浏览器版本不支援此功能',
      發送文字:'推送文字',
    },
    rightClickMenu:{
      從剪貼簿貼上:'从剪贴簿贴上'
    }
  },
  login: {
    formMsg: {
      error1: '取得Blob失败',
      error2: '帐号或密码错误',
      error3: '{state} 授权失败',
      error4: '快速登入失败',
      error5: '请使用HiTA 5扫码',
    },
    currentLang: '中文简体',
    loginBox: {
      title: 'HiTeach CC',
      intro: '无硬件要求，无任何安装HiTeach的设备均可与一体机电子白板互动教学',
      loginWord: '醍摩豆账号登录',
      StudentEntrance: '学生/参与者 加入课堂',
      headertitle: 'HiTeach CC',
      authLoginTitle: '或下列方式登入',
      loginId: '邮箱 / 手机号 / ID',
      password: '密码',
      regist: '免费注册',
      forgotPW: '忘记密码',
      qrcode:{
        text1: '请使用',
        text2: 'HiTA 5',
        text3: '扫描',
        text4: '是否要扫码登入?',
      }
    },
    footer: {
      create: '创建醍摩豆账号'
    }
  },
  create: {
    createWord: '开启教室'
  },
  copyright: 'Copyright © 2024 醍摩豆（成都）信息技术有限公司 版权所有',
  error: {
    required: '不能为空',
    format: {
      default: '格式错误'
    }
  },
  remind: {
    title: '提示',
    content: '当前浏览器不是谷歌浏览器，为了您更好的体检，建议使用谷歌浏览器体验！ ',
    toDownload: '去下载'
  },
  loadingMsg:{
    text1: '<b>初始化</b>......<br/><span class="text-primary">请等待...</span>',
    text2: '<b>登入中</b>......<br/><span class="text-primary">请等待...</span>'
  },
  report: {
    title: '课堂数据总表',
    activityName: '班级/课程',
    hostName: '授课者',
    meterialName: '教材',
    dateTime: '日期时间',
    attendCount: '出席人数',
    attendRate: '出席率',
    collateTaskCount: '发起任务数',
    collateCount: '收到作品数',
    pushCount: '推送资源数',
    totalInteractPoint: '总互动分',
    interactionCount: '互动题数',
    clientInteractionCount: '学生互动总数',
    clientInteractionAverge: '平均互动数',
    quizID: '题序',
    pageOrderStr: '页次',
    score: '配分',
    correctAnswer: '正解',
    correctRateStr: '得分率',
    seatID: '座位号',
    name: '姓名',
    attendState: '出席',
    attendState1: '已出席',
    attendState0: '未出席',
    taskCompleteCount: '作品数',
    interactScore: '互动分',
    answerList: '学生作答',
  },
  memberlist:{
    自由加入:'临时參加动态生成',
    請選擇學生名單:'请选择上课名单',
    創建自訂學生名單:'创建预存的学生名单',
    使用IES5課程名單:'使用IES5课程名单',
    點選左側指定可加入的學生名單: '点击左侧清单选择预存的名单',
    點選Hi進行編輯:'如需编辑预存名单，请按下一步进入系统后从Hi菜单操作',
    下一步:'下一步',
    上一步:'上一步',
    編輯學生:'编辑学生',
    新增學生:'添加学生',
    姓名:'姓名',
    座號:'座位号',
    座號重複:'座位号重复',
    座號不可為空或輸入無法辨認的值:'座位号不可为空或输入无法辨认的值',
    姓名不可為空:'姓名不可为空',
    確定:'确定',
    取消:'取消',
    修改名單名稱:'修改名单名称',
    表單名稱不可為空:'名称不可为空',
    確定刪除後這個名單將無法復原:'确定删除后这个名单将无法复原!',
    刪除:'删除',
    自訂學生名單:'编辑预存名单',
    創建名單:'创建名单',
    暫無數據:'暂无数据',
    新建的學生名單:'创建的学生名单...',
    輸入名單資料:'输入名单数据...',
    自動編號提示字:'无法辨识座位号规则，系统自动编号',
    預覽:'预览转换',
    儲存預覽表格:'保存名单',
    名單範例模板:`输入名单小技巧<br><br>名单的格式为：
    姓名, 座位号 <br>(座位号可省略，如有填写则须每列都填写)<br><br>
    限制<br>
    姓名：字数长度50以内<br>
    座号：不能重复数字，应小于1000
     <br><br>
    范例 <br>
    王大明, 1<br>
    张小凡, 2<br>
    陈士轩, 3<br>`,
    複製範例:'复制范例',
    自訂名單:'我的名单',
    帶有重複座號:'带有重复座位号',
    座號範圍提示字:'座位号应设置于1-999',
    名單人數上限提示字:'名单人数超过上限100人，请删减人数',
    新增單筆名單人數達上限提示字:'名单人数达上限100人，无法添加',
    自訂名單已達上限提示字:'预存名单已达3个上限',
    範例名單: `王大明, 1\n张小凡, 2\n陈士轩, 3\n`
   },
  gptBox:{
    Gpt工具:'Gpt工具箱',
    管理指令:'管理指令',
    常用指令:'常用指令',
    可輸入一段100字以內的指令:'可输入一段100字以内的指令...',
    總結大意:'总结大意',
    整理同義字:'整理同义字',
    等候提示字:'请等候Gpt回答完这个问题再做下一个!',
    等候關閉互動窗提示字:'请等候Gpt回答完这个问题再关闭!',
    預設:'预设',
    繼續:'继续',
    當前語系指令:'请用简体中文，',
    確定:'确定',
    取消:'取消',
    確定刪除後這個分類將無法復原:'确定删除后这个分类将无法复原!',
    刪除:'删除',
    新增分類:"新增分类",
    已達最大上限10個分類:"已达最大上限10个分类",
    暫無數據:"暂无咒语",
    emptyReply:"GPT目前仍在生成中，暂无回应数据，请按右上角「查询生成结果」，或再试一次！",
    queryResult:"查询生成结果",
  }
}
