export default {
  pageButton1: '智慧教室管理仪表板',
  pageButton2: '智慧学校管理表板',
  block1Title1: '帐号启用逐日变化 (每日启用)',
  block1Title2: '课程列表',
  block1SubTitle1: '总教师帐号启用数',
  block1SubTitle2: '总学生帐号启用数',
  block1SubTitle3: '今日启用数',
  block1SubTitle4: '学习历程',
  block1SubTitle5: '参与学生',
  block2SubTitle1: '语文类教室数',
  block2SubTitle2: '数理科教室数',
  block2SubTitle3: '文史类教室数',
  block2SubTitle4: '艺能类教室数',
  block3Title1: '智慧课堂类型',
  block3Title2: '学习历程类型分佈',
  block3Title3: '资源产出类型',
  block3SubTitle1: '今日里程数',
  block3SubTitle2: '昨日里程数',
  block3SubTitle3: '平均里程数',
  block3SubTitle4: '总资源数',
  block4Title1: '学习活动类型',
  block4Title2: '学习活动状态',
  block4Title3: '翻转课堂完成率',
  block4Title4: '线上测验完成率',
  block4Title5: '作业作品完成率',
  block4Title6: '课堂&学习历程&资源产出逐日变化',
  block4SubTitle1: '翻转课堂数量',    
  block4SubTitle2: '线上测验数量',    
  block4SubTitle3: '作业作品数量',    
  block4SubTitle4: '总智慧教室数',    
  block4SubTitle5: '进行中活动', 
  block4SubTitle6: '已完成活动', 
  block4SubTitle7: '未开始活动', 
  text1: '六年级数学',
  text2: '一年级物理',
  text3: '三年级英文',
  text4: '三年级语文',
  text5: '六年级化学',
  text6: '七年级理化',
  text7: '六年级历史',
  text8: '九年级健康教育',
  text9: '二年级童军',
  text10: '六年级地球科学',
  text11: '苏格拉底',
  text12: '电子笔记',
  text13: '上传影片',
  text14: '作业作品',
  text15: '翻转课堂',
  text16: '线上测验',
  text17: '语文类别',
  text18: '数理类别',
  text19: '文史类别',
  text20: '艺能类别',
  text21: '国语文',
  text22: '英语文',
  text23: '数学',
  text24: '生物',
  text25: '化学',
  text26: '物理',
  text27: '历史',
  text28: '地理',
  text29: '体育',
  text30: '舞蹈',
  text31: '模拟测验',
  text32: '成绩登录',
  text33: '线上测验',
  text34: '合併活动',
  text35: '班级竞赛',
  text36: '网路阅卷',
  text37: 'HiTeach',
  text38: '总数量',  
  text39: '题目数', 
  text40: '教材数', 
  text41: '分享课例数', 
  text42: '校本课纲数', 
  text43: '各年级资源产出分佈',
  text44: '一年级',
  text45: '二年级',
  text46: '三年级',
  text47: '四年级',
  text48: '五年级',
  text49: '六年级',
  text50: '课堂总数',
  text51: '完成数量',
}
