import { post } from '@/filter/http'
import { jsFn } from '@/utils/jsFn'
import profile from '@/utils/profile'
let hostname = window.location.hostname
var serverLocation = jsFn.isCN() ? 'cn' : 'global'
if(hostname.indexOf('localhost') === 0 || hostname.indexOf('-rc') > 0) {
   serverLocation += 'test'
}
var apiUrl = profile.ies5Api[serverLocation]
export default {
    // 取得教師資訊
    getUserBlob: function() {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/hiteachcc/get-teacher-info').then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    },
    // 重置暫存空間
    resetBlob: function() {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/hiteachcc/del-blob-temp').then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
        })
    },
    // 取得上課資訊
    getClassInfo: function(data) {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/hiteachcc/create-lesson', data).then(res => {
                let nT = parseInt(Date.parse(new Date())/1000) // 用十碼計算
                let yearLater = nT + 3153600000
                let className = ''
                className += data.cname ? data.cname : ''
                className += data.sname ? ' - ' + data.sname : ''
                let result = {
                    funcs: [
                        { func: 'clients', para: res.client ? res.client : 0, get: nT, exp: yearLater }, // 連線人數
                        { func: 'works', para: 6, get: nT, exp: yearLater }, // 作品收集任務數(因為IES5 沒給就會預設給6
                    ],
                    activityRecordNumber: res.id,
                    materialUrl: res.res,
                    students: res.students,
                    schoolId: data.school,
                    className: className,
                    schoolName: data.schoolname,
                    workingFolder: 'records/',
                    scope: data.sp, // 開課性質
                    blob: {
                        url: res.uri,
                        write: res.sas,
                        read: res.sas
                    }
                }
                resolve(result)
            }, err => {
                reject(err)
            })
        })
    },
    // 更新課堂資訊
    updClassRecord: function(data) {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/hiteachcc/update-lesson-record', data).then(() => {
                resolve(true)
            }, err => {
                reject(err)
            })
        })
    }
}
