import Vue from 'vue'
import Router from 'vue-router'

// ******

//import Board from './views/Board.vue'
//import pdf from './views/pdf.vue'
//import Callback from './views/Callback.vue'
//import MQTTdemo from './views/mqttdemo.vue'
//import Mobileindex from './views/MobileIndex.vue'
//import Mobiletext from './views/MobileText.vue'
//import BasePick from './components/Chart/OptionView.vue'
// import MobileLogin from './views/MobileLogin.vue'
Vue.use(Router)
const Login = () => import('./views/Login')
const Board = () => import('./views/Board')
const pdf = () => import('./views/pdf')
const Callback = () => import('./views/Callback')
const MQTTdemo = () => import('./views/mqttdemo')
// const Mobileindex = () => import('./views/MobileIndex')
// const Mobiletext = () => import('./views/MobileText')
const BasePick = () => import('./components/Chart/OptionView')
// const MobileLogin = () => import('./views/MobileLogin.vue')
const testconnect = () => import('./views/testconnect')
// const testmqtt = () => import('./views/testMqtt')
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: { requiresAuth: false }
        },
        // {
        //     path: '/mobilelogin',
        //     name: 'MobileLogin',
        //     component: MobileLogin
        // },
        {
            path: '/board',
            name: 'board',
            component: Board,
            meta: { requiresAuth: true }
        },
        {
            path: '/callback',
            name: 'Callback',
            component: Callback,
            meta: { requiresAuth: false }
        },
        {
            path: '/pdf',
            name: 'pdf',
            component: pdf,
            meta: { requiresAuth: true }
        },
        {
            path: '/BasePick',
            name: 'BasePick',
            component: BasePick,
            meta: { requiresAuth: true }
        },
        {
            path: '/mqttdemo',
            name: 'MQTTdemo',
            component: MQTTdemo,
            meta: { requiresAuth: true }
        },
        {
            path: '/testconnect',
            name: 'testconnect',
            component: testconnect,
            meta: { requiresAuth: false }
        },
        // {
        //     path: '/testmqtt',
        //     name: 'testmqtt',
        //     component: testmqtt,
        //     meta: { requiresAuth: true }
        // },
    ]
})
