export default {
  pageButton1: '智慧教室管理儀表板',
  pageButton2: '智慧學校管理表板',
  block1Title1: '帳號啟用逐日變化 (每日啟用)',
  block1Title2: '課程列表',
  block1SubTitle1: '總教師帳號啟用數',
  block1SubTitle2: '總學生帳號啟用數',
  block1SubTitle3: '今日啟用數',
  block1SubTitle4: '學習歷程',
  block1SubTitle5: '參與學生',
  block2SubTitle1: '語文類教室數',
  block2SubTitle2: '數理科教室數',
  block2SubTitle3: '文史類教室數',
  block2SubTitle4: '藝能類教室數',
  block3Title1: '智慧課堂類型',
  block3Title2: '學習歷程類型分佈',
  block3Title3: '資源產出類型',
  block3SubTitle1: '今日里程數',
  block3SubTitle2: '昨日里程數',
  block3SubTitle3: '平均里程數',
  block3SubTitle4: '總資源數',
  block4Title1: '學習活動類型',
  block4Title2: '學習活動狀態',
  block4Title3: '翻轉課堂完成率',
  block4Title4: '線上測驗完成率',
  block4Title5: '作業作品完成率',
  block4Title6: '課堂&學習歷程&資源產出逐日變化',
  block4SubTitle1: '翻轉課堂數量',    
  block4SubTitle2: '線上測驗數量',    
  block4SubTitle3: '作業作品數量',    
  block4SubTitle4: '總智慧教室數',    
  block4SubTitle5: '進行中活動', 
  block4SubTitle6: '已完成活動', 
  block4SubTitle7: '未開始活動', 
  text1: '六年級數學',
  text2: '一年級物理',
  text3: '三年級英文',
  text4: '三年級語文',
  text5: '六年級化學',
  text6: '七年級理化',
  text7: '六年級歷史',
  text8: '九年級健康教育',
  text9: '二年級童軍',
  text10: '六年級地球科學',
  text11: '蘇格拉底',
  text12: '電子筆記',
  text13: '上傳影片',
  text14: '作業作品',
  text15: '翻轉課堂',
  text16: '線上測驗',
  text17: '語文類別',
  text18: '數理類別',
  text19: '文史類別',
  text20: '藝能類別',
  text21: '國語文',
  text22: '英語文',
  text23: '數學',
  text24: '生物',
  text25: '化學',
  text26: '物理',
  text27: '歷史',
  text28: '地理',
  text29: '體育',
  text30: '舞蹈',
  text31: '模擬測驗',
  text32: '成績登錄',
  text33: '線上測驗',
  text34: '合併活動',
  text35: '班級競賽',
  text36: '網路閱卷',
  text37: 'HiTeach',
  text38: '總數量',  
  text39: '題目數', 
  text40: '教材數', 
  text41: '分享課例數', 
  text42: '校本課綱數', 
  text43: '各年級資源產出分佈',
  text44: '一年級',
  text45: '二年級',
  text46: '三年級',
  text47: '四年級',
  text48: '五年級',
  text49: '六年級',
  text50: '課堂總數',
  text51: '完成數量',
}
  