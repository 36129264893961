export default {
  pageButton1: 'Smarter Classroom Management',
  pageButton2: 'Smarter School Management',
  block1Title1: 'Account Usage (D)',
  block1Title2: 'Course List',
  block1SubTitle1: 'Total Teacher Accounts',
  block1SubTitle2: 'Total Student Accounts',
  block1SubTitle3: 'Active Today',
  block1SubTitle4: 'Resume',
  block1SubTitle5: 'Students',
  block2SubTitle1: 'Liberal Arts',
  block2SubTitle2: 'Science/Phys',
  block2SubTitle3: 'Hist/Geog',
  block2SubTitle4: 'Music/Art',
  block3Title1: 'Course Type',
  block3Title2: 'Performance Chart',
  block3Title3: 'Source Output (Resource)',
  block3SubTitle1: 'Today\'s Number',
  block3SubTitle2: 'Prev Day\'s Number',
  block3SubTitle3: 'Avg number',
  block3SubTitle4: 'Total Sources',
  block4Title1: 'Activity Type',
  block4Title2: 'Activity Status',
  block4Title3: 'Flipped Classroom',
  block4Title4: 'Online Exam',
  block4Title5: 'Assignment',
  block4Title6: 'course/activity/source output (D)',
  block4SubTitle1: 'Flipped Classroom',
  block4SubTitle2: 'Online Exam',
  block4SubTitle3: 'Assignment',
  block4SubTitle4: 'Total',
  block4SubTitle5: 'Activite',
  block4SubTitle6: 'Finished',
  block4SubTitle7: 'Pending',
  text1: 'Grade 6 Math',
  text2: 'Grade 1 Physics',
  text3: 'Grade 3 English',
  text4: 'Grade 3 Chinese',
  text5: 'Grade 6 Science',
  text6: 'Grade 7 Chemical',
  text7: 'Grade 6 History',
  text8: 'Grade 9 Health Edu',
  text9: 'Grade 2 Scouting',
  text10: 'Grade 6 Earth Science',
  text11: 'Sokrates',
  text12: 'E-note',
  text13: 'Videos',
  text14: 'Assignment',
  text15: 'Flipped Classroom',
  text16: 'Online Exam',
  text17: 'Liberal Arts',
  text18: 'Science/Phys',
  text19: 'Hist/Geog',
  text20: 'Music/Art',
  text21: 'Chinese',
  text22: 'English',
  text23: 'Math',
  text24: 'Biology',
  text25: 'Science',
  text26: 'Physics',
  text27: 'History',
  text28: 'Geography',
  text29: 'PE',
  text30: 'Dancing',
  text31: 'Mock Test',
  text32: 'Score Rec.',
  text33: 'Online Quiz',
  text34: 'Event',
  text35: 'Competition',
  text36: 'Correction',
  text37: 'HiTeach',
  text38: 'Total',
  text39: 'Question(s)',
  text40: 'Material(s)',
  text41: 'Shared Course(s)',
  text42: 'Course Outline(s)',
  text43: 'Source Output (Grade)',
  text44: '1st Grade',
  text45: '2nd Grade',
  text46: '3rd Grade',
  text47: '4th Grade',
  text48: '5th Grade',
  text49: '6th Grade',
  text50: 'Total',
  text51: 'Completed',
}
  