import { fetch, post } from '@/filter/http'
import coreApi from './coreApi'
import ies5Api from './ies5Api'
import bbApi from './bbApi'
export default {
//   //TW获取token 可能用不到 先註解
//   getLoginInfo: function (data) {
//         return post('https://coreapiscn.chinacloudsites.cn/message', data);
//     },
//   getblob: function(){ 可能用不到 先註解
//       return fetch('https://teammodelstorage.blob.core.chinacloudapi.cn/download/pptx/test.json')
//     },
  //登录后获取醍摩豆token
  TeamModelLogin: function (data) {
      return post('/api/login/TeamModelLogin', data);
  },
  //用户信息
  TeamModelInfo: function (data) {
    return post('/api/login/TeamModelInfo', data);
  },
  //上传文件
  fileUpload: function (data) {
    return post('/api/file/UploadDocument', data);
  },
  //上传文件
  uploadBase64: function (data) {
      return post('/common/import/upload-base64', data);
  },
  //获取上传blob后文件
  getIdtoken: function (data) {
      return post('/common/login/tmd-login',data)
  },
  //传输blob的URL，得到解析
  FileAnalysis: function (data) {
      return post('/common/import/parse-doc',data)
  },
  //获取连接访问JSON
  fileJson: function (url) {
      return fetch(url)
  },
  //IM相关
  //根据用户信息创建教室
//   createapi: function (data) {
//       return post('https://api2.teammodel.cn/channel/create',data)
//   },
  //加入教室
//   joinclass: function (data) {
//       return post('https://api2.teammodel.cn/channel/join',data)
//   },
  //发送消息
  tomessage: function (url,data) {
      return post(url,data)
  },


//   //OAuth整合ID 可能用不到 先註解
//   authorize: function (data) {
//       return post('https://api2.teammodel.cn/oauth2/token',data)
//   },
//   //blobauth 可能用不到 先註解
//   blobauthFile: function () {
//       return post('/common/login/blob-auth')
//   },
  coreApi,
  ies5Api,
  bbApi
}
