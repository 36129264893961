import { jsFn } from '@/utils/jsFn'

const KEEPSTORAGE = 'KEEPSTORAGE'
const SETUPFUNCS = 'SETUPFUNCS'

export default {
    namespaced: true,
    state: {
        funcs: [ // 預計以後會開放的功能 以下以最大數量為主)
            { func: 'works', para: '1', get: null, exp: null, active: true, isDeadline: false}, // 作品收集任務數 (掃碼改為 6)
            { func: 'clients', para: '10', get: null, exp: null, active: true, isDeadline: false }, // 連線人數 (掃碼改為 50)
            { func: 'pageqty', para: '50', get: null, exp: null, active: true, isDeadline: false }, // 頁面數
            { func: 'aigpt', para: null, get: null, exp: null, active: false, isDeadline: false }, // AIGPT
        ]
    },
    getters: {
        // 取得指定的功能設定
        getFunc: (state) => (func) => {
            let data = state.funcs.find(item => item.func === func)
            return data
        },
        getFuncAll: (state) => state.funcs
    },
    mutations: {
        [KEEPSTORAGE](state, data) {
            let keys = Object.keys(data)
            keys.forEach(key => {
                state[key] = data[key]
            })
        },
        [SETUPFUNCS](state, data) {
            data.forEach(d => {
                var i = state.funcs.findIndex((e) => e.func == d.func)
                if(i != -1){
                    state.funcs[i] = d
                }
            })
        },
    },
    actions: {
        /**
         * 設定開放的功能
         * @param {Object} data 
         */
        setup(context, data) {
            return new Promise((resolve) => {
                let funcData = []
                let nT = parseInt(Date.parse(new Date())/1000) // 用十碼計算

                data.forEach( (d) => {
                    let exp
                    let stateTmp = context.state.funcs.find( e => e.func === d.func)
                    let index = funcData.findIndex( (e) => e.func == d.func)
                    let tmpExp = nT

                    switch (stateTmp?.func) {
                        case 'aigpt':

                            if(index != -1 && funcData[index].exp > nT) {
                                tmpExp = funcData[index].exp
                            }
        
                            if(stateTmp.exp != null && stateTmp.exp > tmpExp) {
                                tmpExp = stateTmp.exp
                            }

                            exp = d.exp
                            // 如果超過10碼調整為10碼
                            if(exp.toString().length > 10 ) exp = parseInt(exp/1000)

                            if((exp - tmpExp) > 0) {
                                stateTmp.para = d.para
                                stateTmp.exp = d.exp
                                stateTmp.get = d.get
                                stateTmp.isDeadline = true
                                stateTmp.active = true
                            }

                            break;
                        default:
                            exp = d.exp
                            // 如果超過10碼調整為10碼
                            if(exp.toString().length > 10 ) exp = parseInt(exp/1000)

                            if((exp - tmpExp) > 0 && parseInt(stateTmp.para) < parseInt(d.para)) { // 檢查功能是否過期，沒過期比較大小值
                                stateTmp.para = d.para
                                stateTmp.exp = d.exp
                                stateTmp.get = d.get
                                stateTmp.isDeadline = true
                            }
                    }

                    funcData.push(stateTmp)
                })
                context.commit(SETUPFUNCS, funcData)
                context.dispatch('saveState') // 存到sessionStorage
                resolve(true)
            })
        },
        /** 
         * NOTE: 維持 vuex state 的資訊
        */
         keepState(context) {
            if(!jsFn.isEmpty(sessionStorage.getItem('funcsData'))) {
                let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('funcsData'),'utf-8'))
                context.commit(KEEPSTORAGE, data)
            }
        },
        /**
         * NOTE: 儲存現在 state 的資訊
        */
        saveState(context) {
            let saveData = {
                funcs: context.state.funcs
            }
            sessionStorage.setItem('funcsData', encodeURIComponent(JSON.stringify(saveData), 'utf-8'))
        }
    }
}
