export default {
    pageButton1: '智慧教室管理仪表板',
    pageButton2: '智慧学校管理表板',
    block1Title1: '当前启动教室',
    block1Title2: '今日故障教室数',
    block1Title3: '待更新教室数',
    block1Title4: '帐号登入启动课堂数',
    block1Title5: '总教室数',
    hotclass: '教室热度',
    runing: '运行中',
    notInitiated: '未启动',
    repair: '报修中',
    block2Title1: '教室开机数',
    block2Title2: '课堂开课数',
    block2Title3: '课堂参与人数',
    block2Title4: '今日课堂数量变化',
    block2Title5: '今日各年级课堂百分比',    
    block2SubTitle1: '今日数量/ 昨日数量',
    block2SubTitle2: '前7日平均',
    block2SubTitle3: '前30日平均',
    block2SubTitle4: '学期平均',
    block2SubTitle5: '今日使用时数',
    block2SubTitle6: '前7日总数',
    block2SubTitle7: '前30日总数',
    block2SubTitle8: '学期总数',
    block3Title1: '今日智慧教室启用类型',
    block3Title2: '智慧教室版本分布状态',
    block3Title3: '各年级课堂总数',
    block3SubTitle1: '今日开课堂数',
    block3SubTitle2: '上週均课堂数',
    block3SubTitle3: '今日开课百分比',
    block4Title1: '累计课堂时数',
    block4Title2: '苏格拉底议课APP授权状态',
    block4Title3: '课堂作业系统',
    block4SubTitle1: '总计',    
    block4SubTitle2: '累计',
    block4SubTitle3: '最多课堂使用者',
    block4SubTitle4: '最长时数使用者',
    block4SubTitle5: '最高效率课堂使用者',
    block4SubTitle6: '今日开课数',
    block4SubTitle7: '累计开课数',
    block4SubTitle8: '最长课堂时数',
    block4SubTitle9: '累计课堂时数',
    block4SubTitle10: '最高单堂效率值',
    block4SubTitle11: '平均课堂效率值',
    grade1: '一年级',
    grade2: '二年级',
    grade3: '三年级',
    grade4: '四年级',
    grade5: '五年级',
    grade6: '六年级',
    grade7: '七年级',
    grade8: '八年级',
    grade9: '九年级',
    grade10: '十年级',
    grade11: '十一年级',
    grade12: '十二年级',
    selfpace: '书面问答',
    blankPageAdd: '新增档案',
    pptImport: '汇入.pptx',
    powerclick: 'PowerClick',
    hteOpen: '开启.hte',
    Other: '其他',
    today: '今天',
    lastweek: '前七天',
    text1: '通识教室',
    text2: '授权至',
    text3: '今日总课堂数',
}
